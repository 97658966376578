class ImageSize {

    private static readonly _size_strings: string[] = ['small', 'medium', 'large'];

    public static readonly _small: ImageSize = new ImageSize(ImageSize._size_strings[0]);
    public static readonly _medium: ImageSize = new ImageSize(ImageSize._size_strings[1]);
    public static readonly _large: ImageSize = new ImageSize(ImageSize._size_strings[2]);
    public static readonly _full: ImageSize = new ImageSize('');

    constructor(private sizeString: string) {}

    public static getByString(s: string): ImageSize {
        switch (s) {
            case ImageSize._size_strings[0]:
                return ImageSize._small;
            case ImageSize._size_strings[1]:
                return ImageSize._medium;
            case ImageSize._size_strings[2]:
                return ImageSize._large;
            default:
                return ImageSize._full;
        }
    }

    public asString(): string {
        return this.sizeString;
    }
}

export default ImageSize;