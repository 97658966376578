import ImageService from "./ImageService";
import Environment from "./interfaces/Environment";

class SeoService {

    private static readonly _base_keywords: string[] = ["chase the fox", "furry", "furry art",
        "furry fandom", "fursona"];

    constructor(private imageService: ImageService,
                private env: Environment) {}

    public setTitle(title: string): void {
        document.title = title;
        this.getMetaTag('og:title').setAttribute('content', title);
        this.getMetaTag('twitter:title').setAttribute('content', title);
    }

    public setDescription(description: string): void {
        this.getMetaTag('description').setAttribute('content', description);
        this.getMetaTag('og:description').setAttribute('content', description);
        this.getMetaTag('twitter:description').setAttribute('content', description);
    }

    public setKeywords(...keywords: string[]): void {
        let allKeywords: string[] = SeoService._base_keywords.concat(keywords).filter(keyword => keyword);
        this.getMetaTag('keywords').setAttribute('content', allKeywords.join(','));
    }

    public setUrl(url: string): void {
        let fullUrl = url;
        if (!fullUrl.startsWith(this.env.targetClient)) {
            fullUrl = this.env.targetClient + ((fullUrl.startsWith('/')) ? fullUrl.substring(1) : fullUrl);
        }

        this.getMetaTag('og:url').setAttribute('content', fullUrl);
        document.getElementById('canonical-link')?.setAttribute('href', fullUrl);
    }

    public setImage(imageSrc: string): void {
        this.getMetaTag('og:image').setAttribute('content', imageSrc);
    }

    private getMetaTag(property: string): Element {
        return this.getTag('meta', property);
    }

    private getTag(tagName: string, property: string): Element {
        let element: Element | null = document.head.querySelector(`${tagName}[property='${property}']`);

        if (element) {
            return element;
        } else {
            throw new Error(`Tag '${tagName}' with property '${property}' does not exist.`);
        }
    }
}

export default SeoService;