import React from "react";
import DonationInfo from "../../model/DonationInfo";
import './DonationTracker.scss';

interface DonationTrackerProps {
    donations: DonationInfo;
    cause: string;
    years?: string[];
}

const DonationTracker = (props: DonationTrackerProps) => {

    const _chase = 'Chase the Fox';
    const _family = 'Chase\'s Family';
    const _fox_fam = 'The Fox Fam';

    const convertCurrencyToString = (amount: number): string => {
        return Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    }

    const getSortedDonations = (donationInfo: DonationInfo): {name: string, amount: string}[] => {
        let entries = Object.entries(donationInfo);
        if (!entries || entries.length <= 0) {
            return [];
        }

        return entries.sort((a, b) => {
            if (a[0] === _chase) {
                return -1;
            } else if (b[0] === _chase) {
                return 1;
            } else if (a[0] === _fox_fam) {
                return -1;
            } else if (b[0] === _fox_fam) {
                return 1;
            } else if (a[0] === _family) {
                return -1;
            } else if (b[0] === _family) {
                return 1;
            } else if (a[1] !== b[1]) {
                return b[1] - a[1];
            } else {
                return b[0] > a[0] ? -1 : 1;
            }
        }).map(info => {
            return {name: info[0], amount: convertCurrencyToString(info[1])};
        });
    }
    
    const getTotalAmount = (): string => {
        if (Object.keys(props.donations).length <= 0) {
            return '$0.00';
        }

        let total: number = Object.entries(props.donations).map(donationInfo => donationInfo[1])
            .reduce((prev, curr) => prev + curr);
        return convertCurrencyToString(total);
    }

    return (
        <>
            <h3 className="center">
                {props.cause} Donations <br/>
                Total: {getTotalAmount()}
            </h3>
            <table className="donation-tracker-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(props.donations).length > 0 && getSortedDonations(props.donations)
                        .map((donation, index) =>
                        <tr key={`donation-key-${(!donation.name) 
                                ? `${index}` 
                                : donation.name.replace(/\s*/g, '')}`}>
                            <td><span>{donation.name}</span></td>
                            <td>{donation.amount}</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </>
    );
}

export default DonationTracker;