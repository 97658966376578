import React, {useEffect, useRef, useState} from "react";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import PageActions from "../../../../base/PageActions";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import TfGenResult from "../../../../../model/TfGenResult";
import TfGeneratorStore from "../../../../../store/TfGeneratorStore";
import ShareButton from "../../../../../components/share-button/ShareButton";
import ContentBox from "../../../../../components/content-box/ContentBox";
import '../../../../styles/Animation.scss';
import '../../../../styles/Common.scss';
import './TfGeneratorResultPage.scss';

interface TfGeneratorResultPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface TfGeneratorResultPageState {
    fetchComplete: boolean,
    hasResult: boolean
}

interface TfGeneratorResultPageResult {
    creatureName?: string,
    creatureQualifier?: string,
    creatureLink?: string,
    modifier?: string
}

const TfGeneratorResultPage = (props: TfGeneratorResultPageProps) => {

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Random Transformation Generator - Chase the Fox`,
        description: `Feel like changing for a change? What will Chase zap you into next?`,
        keywords: ["TF Generator", "transformation generator", "transformation randomizer", "TF randomizer"],
        imgSrc: `chase/assets/tf_victim.png`,
        url: `tf-generator`
    });

    const [isAnimating, setAnimating] = useState<boolean>(true);
    const [state, setState] =
        useState<TfGeneratorResultPageState>({
            fetchComplete: false,
            hasResult: false
        });

    const navigateTo = useNavigate();

    const [params] = useSearchParams();

    const result = useRef<TfGeneratorResultPageResult>();

    const initiateAnimationTimer = () => {
        setInterval(() => setAnimating(false), 1500);
    };

    const fetchResult = async (params: URLSearchParams): Promise<TfGenResult> => {
        if (params.has("quick")) {
            return await page.http().getTransformationQuickResult();
        } else {
            return await page.http().getTransformationGeneratorResult(TfGeneratorStore.getState());
        }
    };

    useEffect(() => {
        initiateAnimationTimer();
        fetchResult(params)
            .then(response => {
                if (!response.success) {
                    result.current = undefined;
                } else {
                    let qualifier = '';
                    if (response.casarokanUnique) {
                        qualifier = 'Casarokan';
                    } else if (response.mythical) {
                        qualifier = 'Mythical';
                    }

                    result.current = {
                        creatureName: (response.subName) ? response.subName : response.name,
                        creatureQualifier: qualifier,
                        creatureLink: response.link,
                        modifier: response.modifier
                    };
                }
            })
            .catch(() => navigateTo("/error", {replace: false}))
            .finally(() => setState({fetchComplete: true, hasResult: !!result.current}));
    }, []);

    const getFullCreatureName = (): string => {
        if (!result.current || !result.current?.creatureName) {
            return '';
        }

        return (result.current.creatureQualifier)
            ? `${result.current.creatureQualifier} ${result.current.creatureName}`
            : result.current.creatureName;
    }

    const getSocialMediaText = (): string => {
        return `Oh no! Chase the Fox transformed me into a ${getFullCreatureName()}! What will he change you 
                into? ${props.env.targetClient}tf-generator`;
    }

    const renderResult = (): JSX.Element => {
        return (
            <div className="result-display fade-in">
                {(state.fetchComplete && state.hasResult)
                    ? <>
                        <div className="result-panel">
                            <h2 className="headline">
                                You have been transformed into
                                a {result.current!.modifier || ''} {result.current!.creatureLink
                                ? <a href={`/${result.current!.creatureLink}`} target="_blank"
                                     rel="noopener noreferrer">{getFullCreatureName()}</a>
                                : <span>{getFullCreatureName()}</span>}!
                            </h2>
                            <p className="result-message center">
                                Look at the bright side: It could have been worse, right? If you like this little
                                thing I put together, then please consider sharing it with everyone! If you'd like to
                                try again, <NavLink to={`/tf-generator`}>click here</NavLink>.
                            </p>
                            <ul className="result-tweet">
                                <li>
                                    <ShareButton type="Twitter"
                                                 text={getSocialMediaText()}
                                                 imageService={page.image()}
                                                 deviceService={page.device()}/>
                                </li>
                                <li>
                                    <ShareButton type="Bluesky"
                                                 text={getSocialMediaText()}
                                                 imageService={page.image()}
                                                 deviceService={page.device()}/>
                                </li>
                                <li>
                                    <ShareButton type="Mastodon"
                                                 text={getSocialMediaText()}
                                                 imageService={page.image()}
                                                 deviceService={page.device()}/>
                                </li>
                            </ul>
                            <img className="result-image"
                                 src={page.image().getCdnImageSrc('chase/assets/tf_victim.png')}
                                 alt={`This is you. You are now a ${getFullCreatureName()}.`}
                                 title={`This is you. You are now a ${getFullCreatureName()}.`}/>
                        </div>
                    </>
                    : <>
                        <div className="result-panel">
                            <h2 className="headline">Oops! I guess I missed...</h2>
                            <p>
                                Something went wrong. I couldn't really think of anything based on the options you
                                gave me or maybe it was just a magical misfire or something. If you wanna try again,
                                you can go back or <NavLink to={`/tf-generator`}>click here</NavLink>. Sorry about
                                that!
                            </p>
                            <img src={page.image().getCdnImageSrc('chase/assets/chase_confused.png')}
                                 alt="What happened?"
                                 title="Ummm? Oops?"/>
                        </div>
                    </>
                }
            </div>
        );
    }

    return page.generateMetaContent(
        <>
            <ContentBox className="tf-gen-result">
                {isAnimating
                    ?
                        <>
                            <div className="result-waiting fade-in-out" hidden={!isAnimating}>
                                <img src={page.image().getCdnImageSrc('chase/assets/zap.png')}
                                     alt="ZAP!"
                                     title="A brief flash of Chase's magic appears!"/>
                            </div>
                        </>
                    : renderResult()}
            </ContentBox>
        </>
    );
}

export default TfGeneratorResultPage;