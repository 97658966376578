import {configureStore, createSlice, PayloadAction} from "@reduxjs/toolkit";
import FursonaGenQuery from "../model/FursonaGenQuery";

const _fur_gen_default_state: FursonaGenQuery = {
    category: 'ALL',
    colors: 0,
    colorSet: 'ALL',
    modifier: 'NONE'
};

const _fur_gen_slice = createSlice({
    name: 'fursona-generator',
    initialState: _fur_gen_default_state as FursonaGenQuery,
    reducers: {
        updateCategory: (store: FursonaGenQuery, action: PayloadAction<string>) => { return {...store, category: action.payload} },
        updateColorSet: (store: FursonaGenQuery, action: PayloadAction<string>) => { return {...store, colorSet: action.payload} },
        updateColors: (store: FursonaGenQuery, action: PayloadAction<number>) => { return {...store, colors: action.payload} },
        updateModifier: (store: FursonaGenQuery, action: PayloadAction<string>) => { return {...store, modifier: action.payload} },
        reset: () => { return {..._fur_gen_default_state} }
    }
});

const _fursona_gen_store = configureStore({
    reducer: _fur_gen_slice.reducer
});

export const FursonaGenActions = _fur_gen_slice.actions;
export default _fursona_gen_store;