import React, {useEffect, useMemo, useRef, useState} from "react";
import {Navigate, Params, useNavigate, useParams} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import PageActions from "../../../../base/PageActions";

interface ComicLatestRedirectPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface ComicLatestRedirectPageResult {
    comic: string,
    chapter: number,
    page?: number,
}

interface ComicLatestRedirectPageParams extends Params {
    comic: string
}

const ComicLatestRedirectPage = (props: ComicLatestRedirectPageProps) => {

    const params = useParams<ComicLatestRedirectPageParams>();

    const result = useRef<ComicLatestRedirectPageResult>({
        comic: params.comic!,
        chapter: params.chapter ? +params.chapter : 0
    });

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `${props.dependencies.namingService.capitalizeName(result.current.comic)}: Latest Page - Chase the Fox`,
        description: `The most recent page of Tale Chasers`,
        keywords: ["comic", "webcomic", result.current.comic],
        imgSrc: `chase/comic/talechasers/tale_chasers_logo.png`,
        url: `comics/${result.current.comic}/latest`
    }), [props, result.current]);

    const [hasFetchedResult, setFetchedResult] = useState<boolean>(false);

    const navigateTo = useNavigate();

    useEffect(() => {
        if (!result.current.comic) {
            navigateTo("/error", {replace: true});
        }

        page.http().getLatestComicPage(result.current.comic)
            .then(response => {
                if (!response || response.length < 2) {
                    navigateTo("/error", {replace: true});
                } else {
                    let latestChapter = response[0];
                    let latestPage = response[1];
                    result.current = {
                        ...result.current,
                        chapter: latestChapter,
                        page: latestPage
                    };
                }
            })
            .catch(() => navigateTo("/error", {replace: true}))
            .finally(() => setFetchedResult(true));
    }, []);

    return page.generateMetaContent(
        <>
            {hasFetchedResult
                ? <Navigate replace to={`/comics/${params.comic}/${result.current!.chapter}/${result.current!.page}`}/>
                : <></>}
        </>
    );
}

export default ComicLatestRedirectPage;