import React, {useState} from "react";
import ImageService from "../../services/ImageService";
import DeviceService from "../../services/DeviceService";
import './ShareButton.scss';

interface ShareButtonProps {
    type: 'Twitter' | 'Mastodon' | 'Bluesky',
    text: string,
    imageService?: ImageService,
    deviceService?: DeviceService
}

const _twitter_default_domain: string = "twitter.com";
const _mastodon_default_domain: string = "mastodon.social";
const _bluesky_default_domain: string = "bsky.app";

const _twitter_share_path: string = "intent/tweet";
const _mastodon_share_path: string = "share";
const _bluesky_share_path: string = "intent/compose";

const ShareButton = (props: ShareButtonProps) => {

    const [domain, setDomain] = useState<string>()

    const getDomain = (): string => {
        switch (props.type) {
            case "Twitter":
                return _twitter_default_domain;
            case "Mastodon":
                return (domain && domain.trim().length > 0) ? domain : _mastodon_default_domain;
            case "Bluesky":
                return (domain && domain.trim().length > 0) ? domain : _bluesky_default_domain;
        }
    };

    const getPath = (): string => {
        switch (props.type) {
            case "Twitter":
                return _twitter_share_path;
            case "Mastodon":
                return _mastodon_share_path;
            case "Bluesky":
                return _bluesky_share_path;
        }
    };

    const getLogoImage = (): JSX.Element => {
        if (!props.imageService) {
            return <></>;
        }

        return <img src={props.imageService.getCdnImageSrc(`common/social/${props.type.toLowerCase()}_logo.png`)}
                    alt={props.type}
                    title={`${props.type} logo`}/>
    };

    const getHref = (): string => {
        if (props.type === 'Bluesky' && props.deviceService && props.deviceService.isMobile()) {
            return `bluesky://intent/compose?text=${props.text}`;
        }

        return `https://${getDomain()}/${getPath()}?text=${props.text}`;
    }

    return (
        <div className="share-button-container">
            <a className="share-button"
               href={getHref()}
               target="_blank"
               rel="noopener noreferrer">
                {getLogoImage()}
                <p><span>
                    Share on {props.type}
                </span></p>
            </a>
            {props.type === 'Mastodon'
                && <input className="share-domain"
                          type="text"
                          placeholder={_mastodon_default_domain}
                          onChange={(e) => setDomain(e.target.value)}/>}
        </div>
    );
}

export default ShareButton;