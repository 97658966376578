import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import SiteLayout from "../layouts/chase/SiteLayout";
import AdventurersRouter from "./cga/AdventurersRouter";
import Home from "../pages/chase/home/Home";
import MissingPage from "../pages/chase/400/MissingPage";
import ErrorPage from "../pages/chase/500/ErrorPage";
import Dependencies from "../utilities/Dependencies";
import Environment from "../services/interfaces/Environment";
import QrCodeLandingPage from "../pages/chase/qr-landing/QrCodeLandingPage";
import RedirectsRouter from "./RedirectsRouter";
import StoryRouter from "./chase/StoryRouter";
import ArtRouter from "./chase/ArtRouter";
import FunRouter from "./chase/FunRouter";
import SiteInfoRouter from "./chase/SiteInfoRouter";
import StreamRouter from "./chase/StreamRouter";

interface SiteRouterProps {
    deps: Dependencies,
    env: Environment
}

class SiteRouter extends React.Component<SiteRouterProps> {

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SiteLayout window={this.props.deps.deviceService!}/>}>
                        <Route index element={<Home dependencies={this.props.deps} env={this.props.env}/>}/>
                        <Route path="home" element={<Navigate replace to="/"/>}/>
                        {StoryRouter.getRoutes(this.props.deps, this.props.env)}
                        {StreamRouter.getRoutes(this.props.deps, this.props.env)}
                        {ArtRouter.getRoutes(this.props.deps, this.props.env)}
                        {FunRouter.getRoutes(this.props.deps, this.props.env)}
                        {SiteInfoRouter.getRoutes(this.props.deps, this.props.env)}
                        <Route path="qr">
                            <Route index element={<QrCodeLandingPage dependencies={this.props.deps} env={this.props.env}/>}/>
                        </Route>
                        <Route path="cga">
                            {AdventurersRouter.getRoutes("/cga", this.props.deps, this.props.env)}
                        </Route>
                        {RedirectsRouter.getRoutes()}
                        <Route path="error" element={<ErrorPage/>}/>
                        <Route path="404" element={<MissingPage/>}/>
                        <Route path="*" element={<Navigate replace to={"/404"}/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}

export default SiteRouter;