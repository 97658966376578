import React from "react";
import './ComicPageView.scss';

interface ComicPageViewProps {
    fullImageSrc: string,
    webImageSrc: string,
    loading: boolean,
    onLoad: () => void
}

const ComicPageView = (props: ComicPageViewProps) => {

    return (
        <div className="comic-page-view">
            <div className="spinner-view" hidden={!props.loading}>
                <img src="/comic/spinner.gif" alt="Loading..."/>
            </div>
            <div className="comic-view">
                <a href={props.fullImageSrc} target="_blank" rel="noopener noreferrer">
                    <img src={props.webImageSrc}
                         alt="Current page"
                         title="Click to open a tab with the full image!"
                         loading="eager"
                         onLoad={props.onLoad}/>
                </a>
            </div>
        </div>
    );
}

export default ComicPageView;