import React, {FormEvent, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import FursonaGeneratorStore, {FursonaGenActions} from "../../../../store/FursonaGeneratorStore";
import FursonaGenQuery from "../../../../model/FursonaGenQuery";
import ContentBox from "../../../../components/content-box/ContentBox";
import PageActions from "../../../base/PageActions";
import {
    FursonaGeneratorModifiers,
    GeneratorCategory,
    GeneratorColorSet
} from "../../../../model/generator/GeneratorTypes";
import '../../../styles/Common.scss';
import '../../../styles/Form.scss';
import './FursonaGeneratorPage.scss';

interface FursonaGeneratorPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface FursonaGeneratorPageState {
    query: FursonaGenQuery
}

const FursonaGeneratorPage = (props: FursonaGeneratorPageProps) => {

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Random Fursona Generator - Chase the Fox`,
        description: `Looking for inspiration? Generate a random fursona for yourself or as a prompt for doodling!`,
        keywords: ["fursona generator", "fursona randomizer", "furry generator", "furry randomizer"],
        imgSrc: `chase/assets/chase_artist.png`,
        url: `fursona-generator`
    });

    const [state, setState] = useState<FursonaGeneratorPageState>({
        query: {...FursonaGeneratorStore.getState()}
    });

    const navigateTo = useNavigate();

    const submitForm = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        page.device().goToTop();
        navigateTo('result');
    }

    return page.generateMetaContent(
        <>
            <ContentBox header="Fursona Generator (Beta)">
                <div className="fur-gen-intro">
                    <img src={page.image().getCdnImageSrc('chase/assets/chase_thinking.png')}
                         alt="Hmmm..."
                         title="What I should do...?"/>
                    <p>
                        I've set up this tool to provide inspiration to those who seek it. Are you looking for an
                        idea for a new original character or fursona? Are you looking for a fun drawing prompt?
                        Are you seeking something out of the ordinary to go crazy with? Give this generator a shot
                        then!
                    </p>
                    <p>
                        Choose a category of creature you'd like to see, a type of modifier if you want to use
                        one (such as to make an elemental character or pooltoy), and a set of color options. Once
                        you hit the "Generate!" button, another screen will show you the completely randomized
                        option based on the prompt you gave it. If you need to start over, hit "Reset!"
                    </p>
                </div>
                <form className="form-body" onSubmit={(event) => submitForm(event)}>
                    <div>
                        <div className="fur-form-col-labels">
                            <label htmlFor="category">Category:</label>
                        </div>
                        <div className="fur-form-col-content">
                            <select id="category-select"
                                    name="category"
                                    value={state.query.category}
                                    onChange={(event) => {
                                        let category = event.target.value;
                                        setState({query: {...state.query, category}});
                                        FursonaGeneratorStore.dispatch(FursonaGenActions.updateCategory(category));
                                    }}
                            >
                                {GeneratorCategory.map(key =>
                                    <option key={`fur-gen-category-${key.toLowerCase()}`}
                                            value={key.substring(0, key.endsWith('s') ? key.length - 1 : key.length).toUpperCase()}>
                                        {key}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div>
                        <div className="fur-form-col-labels">
                            <label htmlFor="category">Modifier:</label>
                        </div>
                        <div className="fur-form-col-content">
                            <select id="modifier-select"
                                    name="modifier"
                                    value={state.query.modifier}
                                    onChange={(event) => {
                                        let modifier = event.target.value;
                                        setState({query: {...state.query, modifier: modifier}});
                                        FursonaGeneratorStore.dispatch(FursonaGenActions.updateModifier(modifier));
                                    }}
                            >
                                {Object.entries(FursonaGeneratorModifiers).map(entry =>
                                    <option key={`fur-gen-modifier-${entry[0]}`}
                                            value={entry[0]}>
                                        {entry[1]}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div>
                        <div className="fur-form-col-labels">
                            <label htmlFor="color-count">Number of Colors:</label>
                        </div>
                        <div className="fur-form-col-content">
                            <select id="color-count-select"
                                    name="color-count"
                                    value={state.query.colors}
                                    onChange={(event) => {
                                        let colors = +event.target.value;
                                        setState({query: {...state.query, colors}});
                                        FursonaGeneratorStore.dispatch(FursonaGenActions.updateColors(+event.target.value));
                                    }}
                            >
                                {[0, 1, 2, 3].map(num => <option key={`color-${num}`} value={num}>{num}</option>)}
                            </select>
                        </div>
                    </div>
                    <div>
                        <div className="fur-form-col-labels">
                            <label htmlFor="category">Color Set:</label>
                        </div>
                        <div className="fur-form-col-content">
                            <select id="color-set-select"
                                    name="color-set"
                                    value={state.query.colorSet}
                                    disabled={state.query.colors <= 0}
                                    onChange={(event) => {
                                        let colorSet = event.target.value;
                                        setState({query: {...state.query, colorSet}});
                                        FursonaGeneratorStore.dispatch(FursonaGenActions.updateColorSet(colorSet));
                                    }}
                            >
                                {Object.keys(GeneratorColorSet).map(key =>
                                    <option key={`fur-gen-color-set-${key.toLowerCase()}`}
                                            value={key}>
                                        {key.charAt(0) + key.substring(1).toLowerCase()}
                                    </option>
                                )}
                            </select>
                            <span className="fur-color-set-note" hidden={state.query.colors <= 0}>
                                {GeneratorColorSet.hasOwnProperty(state.query.colorSet!)
                                    ? `(${GeneratorColorSet[state.query.colorSet!]})`
                                    : ''}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="fur-form-buttons">
                            <input type="submit"
                                   value="Generate!"/>
                            <input type="button"
                                   value="Reset!"
                                   onClick={() => {
                                       FursonaGeneratorStore.dispatch(FursonaGenActions.reset());
                                       setState({query: {...FursonaGeneratorStore.getState()}});
                                   }}
                            />
                        </div>
                    </div>
                </form>
                <div>
                    <p className="center">
                        You can find the full species list by
                        clicking <NavLink to="list" target="_blank" rel="noreferrer noopener">here</NavLink>.
                    </p>
                </div>
            </ContentBox>
        </>
    );
}

export default FursonaGeneratorPage;