import React, {useState} from "react";
import {Outlet} from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SiteTheme from "../../model/SiteTheme";
import DeviceService from "../../services/DeviceService";
import AdventurersTheme from "../../themes/cga/AdventurersTheme";
import ChaseTheme from "../../themes/chase/ChaseTheme";
import './SiteLayout.scss';

interface SiteLayoutProps {
    window: DeviceService
}

const SiteLayout = (props: SiteLayoutProps) => {

    const getTheme = (path: string): SiteTheme => {
        if (path && path.includes("cga")) {
            return AdventurersTheme;
        }

        return ChaseTheme;
    }
    
    const [theme, setTheme] = useState<SiteTheme>(getTheme(props.window.getBrowserUrl()));

    const updateTheme = (path: string) => {
        setTheme(getTheme(path));
    }

    return (
        <>
            <Header theme={theme} window={props.window} themeUpdater={updateTheme}/>
            <div className="main-body">
                <div className="content-body">
                    <Outlet/>
                </div>
            </div>
            <Footer theme={theme} window={props.window} themeUpdater={updateTheme}/>
        </>
    );
}

export default SiteLayout;