interface SarCharacterData {
    shortName: string;
    fullName: string;
    altText: string;
    description: string;
}

export const _base_character_list: SarCharacterData[] = [
    {
        shortName: 'chase',
        fullName: 'Chase Reynard',
        altText: 'A base Super Fox in a purple tropical shirt and shades',
        description: `Chase, often referred to out of world as "Super Chase", is a carefree critter who lives on an
                      island somewhere south of Super Animal World. He tends to spend his days doing nothing and
                      bothering the other inhabitants of the island. Think of regular Chase but with more goofy and
                      chaotic energy. He would love nothing more than not to take part in the Royale, but he winds up
                      forced into it anyway.`
    },
    {
        shortName: 'vixy',
        fullName: 'Vixy Foxworth',
        altText: 'A pink Super Fox in a dress and glasses',
        description: `Vixy is a competent individual who can hold her own in battle. She has a serious demeanor about 
                      her and has no time for nonsense, so she will politely ask you to stop with the gratuitous animal
                      puns. When pushed too far, she is not afraid to get violent and certainly has a darker side to
                      her usual collected personality. Essentially, she acts as the polar opposite to Chase.`
    },
    {
        shortName: 'snow',
        fullName: 'Snow Flocke',
        altText: 'A blue Super Skunk with a scarf',
        description: `Snow is quite the jovial personality. He loves his dad jokes and will tell them every opportunity
                      he can get. In fact, he has a very fatherly approach to things and cares for his friends as such.
                      Snow does have a temper though and lets folks know when something bothers him. He has a peculiar 
                      fascination with weather, and it will often change his disposition. He is gleeful when it's sunny
                      and gloomy when it rains.`
    },
    {
        shortName: 'malichi',
        fullName: 'Malichi Mechanaurus',
        altText: 'A white Super Mouse in a lab coat with goggles',
        description: `Malichi is a genius inventor, coming up with all sorts of gizmos and weapons in his lab all the
                      time. He prefers to work alone and on his own projects in the lab, but will begrudgingly get his
                      hands dirty or join a team when the situation calls for it. Everyone strangely seems to believe
                      Malichi is the shortest Super Animal on the island, regardless of the fact he's practically, if
                      not, the same size as everyone else...`
    },
    {
        shortName: 'katrina',
        fullName: 'Katrina Hathaway',
        altText: 'A black Super Cat wearing witch garb',
        description: `Katrina is very catty in character. She holds herself above all others and is not above deceit
                      and manipulation to get her way. Nevertheless, she can be quite affectionate, even if it's
                      sometimes another ruse. Katrina's a bit eccentric as well, especially with her studies of the
                      occult. Banan and the strange happenings surrounding their followers peaks her interest most of 
                      all.`
    },
    {
        shortName: 'preston',
        fullName: 'Preston D. G. Tatum',
        altText: 'A Super Werewolf with a tux and top hat',
        description: `Preston is downright insane. He is often antagonizing the other Super Animals on the island in
                      hopes of one day taking over. He has greater aspirations, however, of taking over Super Animal
                      World and perhaps even the world at large. He is conniving yet strange and will get what he wants
                      by ay means necessary, no matter how convoluted the plan may seem. He claims to know magic, but
                      his spells are just another of his tricks. It's all smoke and mirrors.`
    },
    {
        shortName: 'ruby',
        fullName: 'Ruby Walker',
        altText: 'A yellow Super Otter in safari gear',
        description: `Ruby is a bundle of energy, always bouncing around and being her best super self! She is always
                      ready to drag her friends into an adventure, sometimes kicking and screaming. Strangely enough
                      for an otter, Ruby is a vegetarian who won't touch fish; it makes others wonder if there's
                      something more to her. She is a huge history buff and will tell you anything you need to know
                      about Super Animal World. Her favorite pastime is exploring the pyramid in Super Saharaland.`
    }
];

export default SarCharacterData;