import React, {ReactElement} from "react";
import {Navigate, Route} from "react-router-dom";
import Dependencies from "../../utilities/Dependencies";
import Environment from "../../services/interfaces/Environment";
import StreamInfoPage from "../../pages/chase/stream/StreamInfoPage";
import PokemonStreamPage from "../../pages/chase/stream/pokemon/PokemonStreamPage";
import PokemonPlayerPage from "../../pages/chase/stream/pokemon/gym-challenge/player/PokemonPlayerPage";
import SarStreamPage from "../../pages/chase/stream/sar/SarStreamPage";
import CharityStreamPage from "../../pages/chase/stream/charity/CharityStreamPage";
import PokemonGymStreamPage from "../../pages/chase/stream/pokemon/gym-challenge/PokemonGymStreamPage";
import AlsCharityStreamPage from "../../pages/chase/stream/charity/als/AlsCharityStreamPage";
import GoCharityStreamPage from "../../pages/chase/stream/charity/go/GoCharityStreamPage";
import SarCharacterStreamPage from "../../pages/chase/stream/sar/characters/SarCharacterStreamPage";
import KkaCharityStreamPage from "../../pages/chase/stream/charity/kka/KkaCharityStreamPage";

class StreamRouter {

    public static getRoutes(deps: Dependencies, env: Environment): ReactElement {
        return (
            <>
                <Route path="stream">
                    <Route index element={<StreamInfoPage dependencies={deps} env={env}/>}/>
                    <Route path="pokemon">
                        <Route index element={<PokemonStreamPage dependencies={deps} env={env}/>}/>
                        <Route path="gym-challenge">
                            <Route index element={<PokemonGymStreamPage dependencies={deps} env={env}/>}/>
                            <Route path="players">
                                <Route index element={<Navigate to="/stream/pokemon/gym-challenge"/>}/>
                                <Route path=":player" element={<PokemonPlayerPage dependencies={deps} env={env}/>}/>
                            </Route>
                        </Route>
                    </Route>
                    <Route path="sar">
                        <Route index element={<SarStreamPage dependencies={deps} env={env}/>}/>
                        <Route path="characters" element={<SarCharacterStreamPage dependencies={deps} env={env}/>}/>
                    </Route>
                    <Route path="charities">
                        <Route index element={<CharityStreamPage dependencies={deps} env={env}/>}/>
                        <Route path="als">
                            <Route index element={<AlsCharityStreamPage dependencies={deps} env={env}/>}/>
                        </Route>
                        <Route path="go">
                            <Route index element={<GoCharityStreamPage dependencies={deps} env={env}/>}/>
                        </Route>
                        <Route path="kka">
                            <Route index element={<KkaCharityStreamPage dependencies={deps} env={env}/>}/>
                        </Route>
                    </Route>
                </Route>
            </>
        );
    }
}

export default StreamRouter;