import React from "react";
import SpeechBubble from "../../../components/speech-bubble/SpeechBubble";
import ContentBox from "../../../components/content-box/ContentBox";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import './ErrorPage.scss';

const ErrorPage = () => {
    return (
        <>
            <Helmet>
                <title>Error - Chase the Fox</title>
            </Helmet>
            <ContentBox className="silhouette-error">
                <div className="page-500">
                    <SpeechBubble text="Eep! I really hate this part..."/>
                    <h3 className="side-text">
                        It looks like an error occurred. Could you
                        please <NavLink to="/contact">contact Chase</NavLink> and let him know what happened? If you
                        need to get back to the home page, <NavLink to="/">you can click here!</NavLink>
                    </h3>
                </div>
            </ContentBox>
        </>
    );
}

export default ErrorPage;