import React, {useMemo} from "react";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import ContentBox from "../../../../components/content-box/ContentBox";
import PageActions from "../../../base/PageActions";
import '../../../styles/Common.scss';
import './ChaseMagicPage.scss';

interface ChaseMagicPageProps {
    dependencies: Dependencies,
    env: Environment
}

const ChaseMagicPage = (props: ChaseMagicPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Chase's Magic - Chase the Fox`,
        description: `Using his chrysolite pendant, Chase can transform himself and others at will.`,
        keywords: ["chase's magic", "chase's pendant", "shapeshifting", "shapeshifter", "transformation"],
        imgSrc: `chase/assets/chase_magic_zap.png`,
        url: `chasemagic`
    }), [props]);

    return page.generateMetaContent(
        <>
            <ContentBox className="world-content" header="Chase's Shapeshifting Powers">
                <p>
                    Chase has the power to change his own form or the forms of others using his magic
                    abilities. For the most part his magic follows the general guidelines of magic in his
                    world. However, because the source of his power comes from his chrysolite pendant, there
                    are some distinguishing characteristics of his transformation magic.
                </p>
                <hr/>
                <div className="chase-magic-image">
                    <img src={page.image().getCdnImageSrc(`chase/assets/magic/chase_tf_pendant.png`)}
                         alt="A green pendant with a red rune inside"
                         title="Chase's magic pendant"/>
                </div>
                <p className="chase-magic-text">
                    Chase relies on a set of conditions to be met in order to use his magic. First of all,
                    he has to be in possession of his chrysolite pendant. The runed artifact acts as the
                    source of his power. He usually hides it underneath his shirt for safekeeping. Second,
                    he must be in an animate state. Without the ability to move, he is incapable of tapping
                    into his life force.
                </p>
                <hr/>
                <div className="chase-magic-image">
                    <img src={page.image().getCdnImageSrc(`chase/assets/magic/chase_surprise_tf.png`)}
                         alt="Ruby, holding Chase's pendant, with a surprised Chase, turned into a kangaroo"
                         title="Example of Chase being transformed by someone holding his pendant"/>
                </div>
                <p className="chase-magic-text">
                    Chase's pendant is bound to his body. This means that even if someone takes it, they are
                    not able to use the power held within it. However, this does allow anyone else to
                    manipulate Chase's shapeshifting qualities. The chrysolite pendant reacts to the life
                    force of the wielder and gives them the abilities to transform Chase merely by willing
                    it or wanting him to become something else. This does have the potential to drain his
                    magic power, so it can do great damage to him if placed in the wrong hands.
                </p>
                <hr/>
                <div className="chase-magic-image">
                    <img src={page.image().getCdnImageSrc(`chase/assets/magic/chase_magic_zap.png`)}
                         alt="Chase firing a stream of blue magic from his extended index finger"
                         title="Chase firing off his 'Magic Zap'"/>
                </div>
                <p className="chase-magic-text">
                    Chase's life force reserves give him mana for 10 charges of magic. A simple shapeshift
                    costs him 1 charge. These simple shapeshifts require him to maintain his coloration and
                    markings when changing form. He can then continue to change form at will. Chase can also
                    fire blasts of his magic energy for 1 charge. The energy can come out in a variety of
                    ways, but he usually prefers to fire it off from the tip of his finger in a "magic zap".
                    This works in a similar manner to his simple shapeshift ability: The target of the blast
                    is transformed into a shape of his choosing.
                </p>
                <hr/>
                <div className="chase-magic-image">
                    <img src={page.image().getCdnImageSrc(`chase/assets/magic/chase_runed.png`)}
                         alt="Chase, transformed into a brown river otter, with his back to the camera showing the rune from his pendant emblazoned into his fur"
                         title="The rune from Chase's pendant must be visible on his body when shifting fully"/>
                </div>
                <p className="chase-magic-text">
                    For 2 charges, he can do a simple shapeshift while merging the pendant with his body.
                    This makes him capable of concealing the presence of the relic in forms which would not
                    normally make this possible. For 3 charges, he can do a complex shapeshift. These
                    transformations do not require him to keep his coloration or markings. The only
                    restrictions to these forms are that the pendant must be merged with and bound to his
                    form and he must display the pendant's rune somewhere on his body. Primarily, it is
                    placed squarely on his back or on the back of his hands.
                </p>
                <hr/>
                <div className="chase-magic-image">
                    <img src={page.image().getCdnImageSrc(`chase/assets/magic/chase_chimera.png`)}
                         alt="Chase in his chimera form, an amalgamation of different animals"
                         title="Chase in chimera form"/>
                </div>
                <p className="chase-magic-text">
                    For 5 charges, Chase can take his chimera form. His chimera form is an amalgamation of
                    various other creatures which include dragon, deer, rabbit, falcon, and skunk. This
                    highly integrated form does grant him the most useful abilities of each creature he
                    consists of. The amount of magic energy put into this form also allows him to do simple,
                    1-mana shapeshifts for free until his body tires out. However, Chase is generally
                    bashful about taking this form and prefers not to use it unless absolutely necessary.
                </p>
                <hr/>
                <div className="chase-magic-image">
                    <img src={page.image().getCdnImageSrc(`chase/assets/magic/chase_pup.png`)}
                         alt="Chase as a puppy wearing a pet bandana, demonstrating how his shirt has shifted to match his form"
                         title="Chase wearing a bandana with his shirt pattern"/>
                </div>
                <p className="chase-magic-text">
                    As a side note no matter what form he takes through his shapeshifting abilities, his
                    clothes change form with him. They are all laced with SmartThread, an invention created
                    by Malichi specifically for Chase. SmartThread is capable of detecting the use of his
                    magic and changing shape to fit his new form. He can also use a charge of his magic to
                    change his clothes into any other type of clothing he wants. However, transformations or
                    changes in his body made outside the use of his magic will have no effect on his clothing.
                </p>
            </ContentBox>
        </>
    );
}

export default ChaseMagicPage;