import {useEffect, useState} from "react";
import {createGlobalStyle} from "styled-components";
import * as themeDefinitions from '../themes/themes.json';

const themes = themeDefinitions;

class ThemeUtilities {

    public static readonly useTheme = (path?: string) => {
        const [theme, setTheme] = useState(themes.data.chase);
        const [themeLoaded, setThemeLoaded] = useState(false);

        useEffect(() => {
            if (path && path.includes("cga")) {
                setTheme(themes.data.cga);
            }

            setThemeLoaded(true);
        }, [path]);

        return { theme, themeLoaded };
    };

    public static readonly GlobalStyles = createGlobalStyle`
        :root {
            --primary-color: ${({ theme }) => theme.light.colors.primary};
            --secondary-color: ${({ theme }) => theme.light.colors.secondary};
            --content-box-color: ${({ theme }) => theme.light.colors.content.background};
            --content-box-color-secondary: ${({ theme }) => theme.light.colors.content.secondary};
            --content-box-border-color: ${({ theme }) => theme.light.colors.content.box};
            --content-scroll-border-color: ${({ theme }) => theme.light.colors.content.scroll};
            --content-book-cover: ${({ theme }) => theme.light.colors.content.book.cover};
            --content-book-page: ${({ theme }) => theme.light.colors.content.book.page};
            --primary-text: ${({ theme }) => theme.light.colors.text.primary};
            --secondary-text: ${({ theme }) => theme.light.colors.text.secondary};
            --link-text: ${({ theme }) => theme.light.colors.link.text};
            --link-text-hover: ${({ theme }) => theme.light.colors.link.hover};
            --background-color: ${({ theme }) => theme.light.colors.background.color};
            --background-image: ${({ theme }) => `url(https://cdn.chasethefox.com/m/${theme.sid}/theme/background.png)`};
            --background-image-opacity: ${({ theme }) => theme.light.colors.background.opacity};
            --speech-bubble-fill: ${({ theme }) => theme.light.colors.speech_bubble.fill};
            --speech-bubble-border: ${({ theme }) => theme.light.colors.speech_bubble.border};
            --edge-color: ${({ theme }) => theme.light.colors.edge};
        }
        
        [data-theme="dark"] {
            --primary-color: ${({ theme }) => theme.dark.colors.primary};
            --secondary-color: ${({ theme }) => theme.dark.colors.secondary};
            --content-box-color: ${({ theme }) => theme.dark.colors.content.background};
            --content-box-color-secondary: ${({ theme }) => theme.dark.colors.content.secondary};
            --content-box-border-color: ${({ theme }) => theme.dark.colors.content.box};
            --content-scroll-border-color: ${({ theme }) => theme.dark.colors.content.scroll};
            --content-book-cover: ${({ theme }) => theme.dark.colors.content.book.cover};
            --content-book-page: ${({ theme }) => theme.dark.colors.content.book.page};
            --primary-text: ${({ theme }) => theme.dark.colors.text.primary};
            --secondary-text: ${({ theme }) => theme.dark.colors.text.secondary};
            --link-text: ${({ theme }) => theme.dark.colors.link.text};
            --link-text-hover: ${({ theme }) => theme.dark.colors.link.hover};
            --background-color: ${({ theme }) => theme.dark.colors.background.color};
            --background-image: ${({ theme }) => `url(https://cdn.chasethefox.com/m/${theme.sid}/theme/background.png)`};
            --background-image-opacity: ${({ theme }) => theme.dark.colors.background.opacity};
            --speech-bubble-fill: ${({ theme }) => theme.dark.colors.speech_bubble.fill};
            --speech-bubble-border: ${({ theme }) => theme.dark.colors.speech_bubble.border};
            --edge-color: ${({ theme }) => theme.dark.colors.edge};
            
            img {
                filter: brightness(.8) contrast(1.2);
            }
        }
    `;
}

export default ThemeUtilities;