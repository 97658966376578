import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Dependencies from "../../../utilities/Dependencies";
import CharacterOption from "../../../model/CharacterOption";
import ImageLink from "../../../components/image-link/ImageLink";
import Environment from "../../../services/interfaces/Environment";
import ContentBox from "../../../components/content-box/ContentBox";
import PageActions from "../../base/PageActions";
import '../../styles/Common.scss';
import '../../styles/Adventurers.scss';
import './AdventurersCharactersPage.scss';

interface AdventurersCharactersPageProps {
    dependencies: Dependencies,
    env: Environment
}

const AdventurersCharactersPage = (props: AdventurersCharactersPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Characters - Caudalglen Guild of Adventurers`,
        description: `Meet the characters of the CGA world and learn their abilities and histories!`,
        keywords: ["oc", "character", "anthropomorphic", "fantasy character", "fantasy world"],
        imgSrc: `cga/characters/aerilyn/1.png`,
        url: `cga/characters`
    }), [props]);

    const [hasFetchedList, setFetchedList] = useState<boolean>(false);

    const navigateTo = useNavigate();

    const list = useRef<CharacterOption[]>();

    useEffect(() => {
        page.http().getGuildCharacterOptions()
            .then(response => {
                if (!response || response.length === 0) {
                    list.current = [];
                } else {
                    list.current = response;
                }
            })
            .catch(() => navigateTo("/error", {replace: false}))
            .finally(() => setFetchedList(true));
    }, []);

    return page.generateMetaContent(
        <>
            <ContentBox design="scroll"
                        header="The CGA has various roles, classes, and abilities. Choose a character to learn more about them!">
                <h3 className="headline">Guild Members</h3>
                <div className="character-lineup">
                    {hasFetchedList && list.current?.filter(option => option.category === 'GUILDMEMBER')
                        .map(option =>
                            <ImageLink
                                key={`character-cga-${option.shortName}`}
                                imgSrc={page.image().getCdnImageSrc(`cga/characters/${option.shortName}/1.png`)}
                                linkUrl={option.shortName}
                                linkName={`${page.naming().capitalizeName(option.shortName)}`}/>
                    )}
                </div>
            </ContentBox>
        </>
    );
}

export default AdventurersCharactersPage;