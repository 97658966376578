import React from "react";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import SpeechBubble from "../../../components/speech-bubble/SpeechBubble";
import ContentBox from "../../../components/content-box/ContentBox";
import './CgaMissingPage.scss';

const CgaMissingPage = () => {

    return (
        <>
            <Helmet>
                <title>404 - Caudalglen Guild of Adventurers</title>
            </Helmet>
            <ContentBox className="rodney-page-missing" design="scroll">
                <div className="page-404">
                    <SpeechBubble text="Heh! That page is...missing. Wonder where it went!"/>
                    <h3 className="side-text">
                        You can use the navigation tool at the top to explore elsewhere on the site
                        or <NavLink to="/cga">click here to return to the CGA home page!</NavLink>
                    </h3>
                </div>
            </ContentBox>
        </>
    );
}

export default CgaMissingPage;