import React from "react";
import CommonProps from "../CommonProps";
import './ContentBox.scss';

interface ContentBoxProps extends CommonProps {
    header?: string,
    design?: 'box' | 'scroll'
}

const ContentBox = (props: ContentBoxProps) => {

    return (
        <>
            <div className={props.design === 'scroll' ? 'content-scroll' : 'content-box'}>
                <div className={`content${!props.className ? '' : ' ' + props.className}`}>
                    {props.header && <h2 className="headline">{props.header}</h2>}
                    {props.children}
                </div>
            </div>
        </>
    );
}

export default ContentBox;