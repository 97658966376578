import React from "react";
import './ComicCommentary.scss';

interface ComicCommentaryProps {
    comment: string
}

const ComicCommentary = (props: ComicCommentaryProps) => {

    return (
        <div className="comic-commentary">
            <img src={`https://cdn.chasethefox.com/m/chase/theme/logo.png`}
                 alt="Chase says, "
                 title="Chase has something to say..." />
            <div className="chase-comment">
                <div className="tail-border"></div>
                <div className="tail-inner"></div>
                <span>{props.comment}</span>
            </div>
        </div>
    );
}

export default ComicCommentary;