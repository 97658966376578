import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import ContentBox from "../../../../../components/content-box/ContentBox";
import DonationTracker from "../../../../../components/donation-tracker/DonationTracker";
import DonationInfo from "../../../../../model/DonationInfo";
import PageActions from "../../../../base/PageActions";
import "../../../../styles/Common.scss";

interface KkaCharityStreamPageProps {
    dependencies: Dependencies,
    env: Environment
}

const KkaCharityStreamPage = (props: KkaCharityStreamPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Charity Event: Kokua Ka 'Alopeke - Chase the Fox`,
        description: `Learn more about Chase's efforts to help Hawaiians in need!`,
        keywords: ["twitch", "stream info", "chasetheredfox", "charity", "kokua ka alopeke"],
        imgSrc: `chase/assets/chase_twitch_icon.png`,
        url: `stream/charities/kka`
    }), [props]);

    const [hasFetchedResult, setFetchedResult] = useState<boolean>(false);

    const result = useRef<DonationInfo>();

    const navigateTo = useNavigate();

    useEffect(() => {
        page.http().getDonations("kka", "2024")
            .then(response => {
                if (!response) {
                    navigateTo("/error", {replace: false});
                } else {
                    result.current = response;
                }
            })
            .catch(() => navigateTo("/error", {replace: false}))
            .finally(() => setFetchedResult(true));
    }, []);

    return (
        <>
            <ContentBox header="I have a special place in my heart for these islands!">
                <div>
                    <img src={page.image().getCdnImageSrc('chase/stream/charities/kka_photo.png')}
                         alt="Chase fundraising for the Hawaii Community Foundation"
                         title="The fox helps!"
                         className="width-50-percent block"/>
                    <p>
                        Kokua Ka 'Alopeke (Hawaiian for "The Fox Helps") is a charity event created by Chase to help
                        provide relief for those living in the Hawaiian islands that need it most. With the high cost
                        of living in Hawaii, it's difficult for those native to the islands to make ends meet sometimes.
                        It seems that too often Asian and Pacific Islanders who call the archipelago home are priced
                        out of their land and forced to move to the mainland United States. Things like the Covid-19
                        pandemic, the Maui wildfires, and the constant threat of beach and land erosion make it all the
                        more difficult to maintain this beautiful paradise in the world.
                    </p>
                    <p>
                        Chase started a Tiltify campaign in 2023 to help raise money for
                        the <strong>Hawaii Community Foundation</strong>, a top rated charity that provides relief to
                        the people of the islands who need it most. During these events, he tells about his experiences
                        there and explains a lot about what makes Hawaii such a magical place for him and so many
                        others. In the future, Chase may support other organizations like this, so stay tuned!
                    </p>
                    <p>
                        Please check out the website for the Hawaii Community Foundation here and consider donating
                        to their general fund or their Maui Strong Fund to aid victims of the
                        wildfires: <a href="https://www.hawaiicommunityfoundation.org/"
                                target="_blank"
                                rel="noopener noreferrer">
                            Hawaii Community Foundation website
                        </a>.
                    </p>
                    {hasFetchedResult &&
                        <DonationTracker key={`donation-tracker-kka-${Object.keys(result.current!).length}`}
                                         cause="Kokua Ka 'Alopeke 2024"
                                         donations={result.current!}/>
                    }
                </div>
            </ContentBox>
        </>
    );
}

export default KkaCharityStreamPage;