import React, {useMemo} from "react";
import {NavLink} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import PageActions from "../../../../base/PageActions";
import '../../../../styles/Common.scss';
import '../WorldPages.scss';

interface WorldPageProps {
    dependencies: Dependencies,
    env: Environment
}

const WorldPage = (props: WorldPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `The World of Casarok - Chase the Fox`,
        description: `Learn about Chase's home, the Earth-like world of Casarok.`,
        keywords: ["casarok", "chase's world", "worldbuilding", "anthropomorphic world", "fantasy world"],
        imgSrc: `chase/world/casarok_view.png`,
        url: `world`
    }), [props]);

    return page.generateMetaContent(
        <>
            <div className="world-book">
                <div className="world-navigation">
                    <span className="world-link">World</span>
                </div>
                <div id="page-left" className="world-book-page">
                    <h1 className="headline">Welcome to the World of Casarok</h1>
                    <img className="block width-80-percent"
                         src={page.image().getCdnImageSrc(`chase/world/casarok_view.png`)}
                         alt="The Mysterious World Casarok"/>
                    <div className="world-content">
                        <p>
                            Casarok is the homeworld of Chase and his friends. This world exists far from the planet
                            Earth, and it is currently unknown whether or not this planet is even located in the same
                            universe. It shares many similarities with Earth such as its composition, development, and
                            diversity of life; however, magic runs deep within the planet brought about by its
                            pantheon of gods and goddesses.
                        </p>
                    </div>
                </div>
                <div id="page-right" className="world-book-page">
                    <h1 className="headline">Table of Contents</h1>
                    <ol className="spaced">
                        <li>
                            <NavLink className="world-link glowing block" to="bestiary">Bestiary</NavLink>
                            The list of unique creatures found in Casarok.
                        </li>
                        <li>
                            <NavLink className="world-link glowing block" to="botanary">Botanary</NavLink>
                            A specification of the various plant life native to this world.
                        </li>
                        <li>
                            <NavLink className="world-link glowing block" to="magic">Magic</NavLink>
                            A guide to the workings of magic.
                        </li>
                        <li>
                            <NavLink className="world-link glowing block" to="geography">Geography</NavLink>
                            The map of the world and its natural landmarks, past and present.
                        </li>
                        <li>
                            <NavLink className="world-link glowing block" to="history">History</NavLink>
                            A brief description of the eras and ages of Casarok.
                        </li>
                        <li>
                            <NavLink className="world-link glowing block" to="languages">Languages</NavLink>
                            Although Common is the most spoken language in the modern world, a variety of different
                            tongues have been spoken through the world's history.
                        </li>
                        <li>
                            <NavLink className="world-link glowing block" to="pantheon">Pantheon</NavLink>
                            The gods and goddesses of Casarok, their domains, and abilities.
                        </li>
                        <li>
                            <NavLink className="world-link glowing block" to="mythology">Mythology</NavLink>
                            A collection of myths passed down by the people of Casarok over time.
                        </li>
                    </ol>
                </div>
            </div>
        </>
    );
}

export default WorldPage;