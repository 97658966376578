import './CasarokCommonWord.scss';
import {FileSizes} from "../../services/ImageService";
import environment from "../../environments/SiteEnvironment";
import CommonProps from "../CommonProps";

interface CasarokCommonWordProps extends CommonProps {
    code: string
}

function getCharacterSrc(name: string): string {
    return environment.targetCdn + FileSizes['thumbnail'] + "chase/world/languages/common/" + name + ".png";
}

const _casarok_common_alphabet_codes: {[code: string]: string} = {
    "A": getCharacterSrc('aeg'),
    "B": getCharacterSrc('baz'),
    "D": getCharacterSrc('dac'),
    "E": getCharacterSrc('ent'),
    "F": getCharacterSrc('fla'),
    "G": getCharacterSrc('gla'),
    "H": getCharacterSrc('her'),
    "I": getCharacterSrc('ig'),
    "L": getCharacterSrc('lum'),
    "M": getCharacterSrc('ma'),
    "N": getCharacterSrc('ni'),
    "O": getCharacterSrc('oc'),
    "P": getCharacterSrc('pen'),
    "R": getCharacterSrc('ru'),
    "S": getCharacterSrc('sen'),
    "T": getCharacterSrc('ten'),
    "U": getCharacterSrc('ung'),
    "W": getCharacterSrc('wic'),
    "X": getCharacterSrc('ex'),
    "Y": getCharacterSrc('yu'),
    "C": getCharacterSrc('chu'),
    "Z": getCharacterSrc('shu'),
    "V": getCharacterSrc('thu'),
    "Q": getCharacterSrc('tsu'),
    "J": getCharacterSrc('ing'),
};
const _casarok_common_punctuation: {[code: string]: string} = {
    ".": getCharacterSrc('period'),
    "!": getCharacterSrc('exclamation'),
    "?": getCharacterSrc('question'),
    ",": getCharacterSrc('comma'),
    ":": getCharacterSrc('colon'),
    ";": getCharacterSrc('semicolon'),
    "'": getCharacterSrc('quote'),
    "\"": getCharacterSrc('double-quote'),
    "-": getCharacterSrc('hyphen'),
    "`": getCharacterSrc('syllable'), // consonant acts as syllable
    " ": getCharacterSrc('space'),
};
const _casarok_common_modifiers: {[code: string]: string} = {
    "^": getCharacterSrc('alt'), // alternate form
    "u": getCharacterSrc('accent-up'), // upward inflection
    "d": getCharacterSrc('accent-down'), // downward inflection
    "s": getCharacterSrc('short'), // shortened
    "r": getCharacterSrc('trill'), // trilled consonant or elongated vowel
    "l": getCharacterSrc('long-vowel'), // long vowel
    "c": getCharacterSrc('capital'), // capitalized
};
const _casarok_common_numerals: {[code: string]: string} = {
    "0": getCharacterSrc('con'),
    "1": getCharacterSrc('surs'),
    "2": getCharacterSrc('par'),
    "3": getCharacterSrc('un'),
    "4": getCharacterSrc('man'),
    "5": getCharacterSrc('ad'),
    "6": getCharacterSrc('ter'),
    "7": getCharacterSrc('max'),
};

const CasarokCommonWord = (props: CasarokCommonWordProps) => {

    return (
        <div className={`common-word ${props.className || ''}`}>
            {Array.from(props.code).map(code => {
                if (Object.keys(_casarok_common_alphabet_codes).includes(code)) {
                    return <img className="common-word-character"
                                src={_casarok_common_alphabet_codes[code]}
                                alt={code} />;
                } else if (Object.keys(_casarok_common_numerals).includes(code)) {
                    return <img className="common-word-character"
                                src={_casarok_common_numerals[code]}
                                alt={code} />;
                } else if (Object.keys(_casarok_common_punctuation).includes(code)) {
                    return <img className="common-word-character"
                                src={_casarok_common_punctuation[code]}
                                alt={code} />;
                } else if (Object.keys(_casarok_common_modifiers).includes(code)) {
                    return <img className="common-word-modifier"
                                src={_casarok_common_modifiers[code]}
                                alt={code} />;
                } else {
                    return <span>?</span>;
                }
            })}
        </div>
    );
}

export default CasarokCommonWord;