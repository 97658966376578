import React from "react";
import {Navigate, NavLink} from "react-router-dom";
import parse, {DOMNode, Element, Text} from 'html-react-parser';
import ImageService from "./ImageService";
import LoggingService from "./LoggingService";

class HtmlService {

    constructor(private imageService: ImageService,
                private loggingService: LoggingService) {}

    public convert(content: string): JSX.Element {
        try {
            return parse(content) as JSX.Element;
        } catch (e) {
            this.loggingService.log(e);
            return <Navigate replace to="/error"/>
        }
    }

    public parse(content: string, aCallback: (path?: string) => any = () => {}, buttonCallback: () => any = () => {}): JSX.Element {
        try {
            let options = {replace: (domNode: DOMNode) => {
                let element: Element = domNode as Element;
                if (element.name === 'a' && element.attribs.href) {
                    return <NavLink className={element.attribs.classname}
                                    to={`/${element.attribs.href}`}
                                    onClick={() => aCallback(element.attribs.href)}>
                        {element.children.map((child, index) => {
                            let node: Element = child as Element;
                            if (node.name === 'img') {
                                return <img key={`link-key-${index}`} src={this.convertImageSource(node.attribs.src)} alt={node.attribs.alt}/>
                            } else {
                                return <span key={`link-key-${index}`}>{(child as Text).data}</span>;
                            }
                        })}
                    </NavLink>;
                } else if (element.name === 'button') {
                    return <button className={element.attribs.classname}
                                   onClick={() => buttonCallback()}>
                        {(element.firstChild! as Text).data}
                    </button>
                }

                if (element.children) {
                    element.children.forEach(node => {
                        options.replace(node);
                    });
                }

                return undefined;
            }};
            return parse(this.makeParseable(content), options) as JSX.Element;
        } catch (e) {
            this.loggingService.log(e);
            return <Navigate replace to="/error"/>
        }
    }

    private makeParseable(html: string): string {
        if (!html) {
            return '<span></span>';
        }

        return '<span>' + html
            .replace(/<br>/g, '<br/>')
            .replace(/class="([^"]*)"/g, 'className="$1"')
            .replace(/\[src]="v1\/world\/images\/([^"]*)"\s*\[size]="([^"]*)"/g,
                `src="${this.convertImageSource("$1")}"`)
            + '</span>';
    }

    // TODO: Fix world JSON data
    private convertImageSource(src: string): string {
        if (src.startsWith('https://cdn')) {
            return src;
        }

        return 'https://cdn.chasethefox.com/m/chase/world/' + src + '.png';
    }
}

export default HtmlService;