class NamingService {

    public capitalizeName(name: string): string {
        if (name.toLowerCase().trim() === 'talechasers') {
            return 'Tale Chasers';
        }

        return name.split(/ /g).map(namePart => namePart.toUpperCase().charAt(0) + namePart.substring(1)).join(' ');
    }

    public getSectionProperName(name: string): string {
        return name.split(/[\s-_]+/g).map(namePart => namePart.toUpperCase().charAt(0) + namePart.substring(1)).join(' ');
    }

    public getKeywordName(name?: string): string {
        return (name) ? name.split(/[\s-_]+/g).join(' ').toLowerCase() : '';
    }

    public getSafeName(name?: string): string {
        return (name) ? name.replace(/<[^>]*>/g, '') : '';
    }
}

export default NamingService;