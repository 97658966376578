import React, {useEffect, useRef, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import ColorPreview from "../../../../../components/color-preview/ColorPreview";
import Color from "../../../../../model/Color";
import FursonaGeneratorStore from "../../../../../store/FursonaGeneratorStore";
import FursonaGenResult from "../../../../../model/FursonaGenResult";
import ShareButton from "../../../../../components/share-button/ShareButton";
import ContentBox from "../../../../../components/content-box/ContentBox";
import PageActions from "../../../../base/PageActions";
import '../../../../styles/Animation.scss';
import '../../../../styles/Common.scss';
import './FursonaGeneratorResultPage.scss';

interface FursonaGeneratorResultPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface FursonaGeneratorResultPageState {
    fetchComplete: boolean,
    hasResult: boolean
}

interface FursonaGeneratorResultPageResult {
    creatureName?: string,
    creatureQualifier?: string,
    colors?: Color[],
    modifier?: string
}

const FursonaGeneratorResultPage = (props: FursonaGeneratorResultPageProps) => {

    const _social_media_message: string =
        `Chase's Fursona Generator gave me *! Give it a try at ${props.env.targetClient}fursona-generator.`;

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Random Fursona Generator - Chase the Fox`,
        description: `Looking for inspiration? Generate a random fursona for yourself or as a prompt for doodling!`,
        keywords: ["fursona generator", "fursona randomizer", "furry generator", "furry randomizer"],
        imgSrc: `chase/assets/chase_artist.png`,
        url: `fursona-generator`
    });

    const [isAnimating, setAnimating] = useState<boolean>(true);
    const [state, setState] =
        useState<FursonaGeneratorResultPageState>({
            fetchComplete: false,
            hasResult: false
        });

    const navigateTo = useNavigate();

    const result = useRef<FursonaGeneratorResultPageResult>();

    const initiateAnimationTimer = () => {
        setInterval(() => setAnimating(false), 1500);
    }

    const fetchResult = async (): Promise<FursonaGenResult> => {
        return await page.http().getFursonaGeneratorResult(FursonaGeneratorStore.getState());
    };

    useEffect(() => {
        initiateAnimationTimer();
        fetchResult()
            .then(response => {
                if (!response.success) {
                    result.current = undefined;
                } else {
                    result.current = {
                        creatureName: (response.subName) ? response.subName : response.name,
                        creatureQualifier: response.mythical ? 'Mythical' : '',
                        colors: response.colors,
                        modifier: response.modifier
                    };
                }
            })
            .catch(() => navigateTo("/error", {replace: false}))
            .finally(() => setState({fetchComplete: true, hasResult: !!result.current}));
    }, []);

    const getFullCreatureName = (): string => {
        if (!result.current || !result.current?.creatureName) {
            return '';
        }

        return (result.current.creatureQualifier)
            ? `${result.current.creatureQualifier} ${result.current.creatureName}`
            : result.current.creatureName;
    }

    const getSocialMediaText = (): string => {
        if (!result.current) {
            return '';
        }

        let colors: string = '';
        if (result.current.colors!.length === 3) {
            colors = `${result.current.colors![0].name}, ${result.current.colors![1].name}, and ${result.current.colors![2].name}`;
        } else if (result.current.colors!.length === 2) {
            colors = `${result.current.colors![0].name} and ${result.current.colors![1].name}`;
        } else if (result.current.colors!.length === 1) {
            colors = result.current.colors![0].name;
        }

        let species: string
            = ` ${result.current.creatureQualifier ? result.current.creatureQualifier + ' ' : ''}*${result.current.creatureName}`;
        species = species.replace('*',
            (result.current.modifier && _social_media_message.length + colors.length + species.length <= 190)
            ? `${result.current.modifier} ` : ' ');

        return _social_media_message.replace('*', colors + species);
    }

    const formatColors = (): JSX.Element => {
        if (!result.current) {
            return <></>;
        }

        if (!result.current.colors || result.current.colors.length <= 0) {
            return <></>;
        } else if (result.current.colors.length === 1) {
            return <span><ColorPreview color={result.current.colors[0]}/></span>;
        } else if (result.current.colors.length === 2) {
            return <span><ColorPreview color={result.current.colors[0]}/> and <ColorPreview color={result.current.colors[1]}/></span>;
        }

        return <span><ColorPreview color={result.current.colors[0]}/>, <ColorPreview color={result.current.colors[1]}/>,
            and <ColorPreview color={result.current.colors[2]}/></span>;
    }

    const renderResult = () => {
        return (
            <div className="fur-gen-result-display fade-in">
                {(state.fetchComplete && state.hasResult)
                    ? <>
                        <div className="fur-gen-result-panel">
                            <h2 className="headline">
                                I came up with
                                a {formatColors()} {result.current!.modifier || ''} <span>{getFullCreatureName()}</span>!
                            </h2>
                            <p className="fur-gen-result-message center">
                                Does this sound like what you're looking for? If not you
                                can <NavLink to={`/fursona-generator`}>click here</NavLink> to try again.
                            </p>
                            <ul className="fur-gen-result-tweet">
                                <li>
                                    <ShareButton type="Twitter"
                                                 text={getSocialMediaText()}
                                                 imageService={page.image()}
                                                 deviceService={page.device()}/>
                                </li>
                                <li>
                                    <ShareButton type="Bluesky"
                                                 text={getSocialMediaText()}
                                                 imageService={page.image()}
                                                 deviceService={page.device()}/>
                                </li>
                                <li>
                                    <ShareButton type="Mastodon"
                                                 text={getSocialMediaText()}
                                                 imageService={page.image()}
                                                 deviceService={page.device()}/>
                                </li>
                            </ul>
                            <img className="fur-gen-result-image"
                                 src={page.image().getCdnImageSrc('chase/assets/chase_artist.png')}
                                 alt='Chase has an idea!'
                                 title={`My answer to your request was ${getFullCreatureName()}.`}/>
                        </div>
                    </>
                    : <>
                        <div className="fur-gen-result-waiting">
                            <h2 className="headline">Huh? Wait a minute...</h2>
                            <p>
                                Something went wrong. I couldn't really think of anything based on the options you
                                gave me. If you wanna try again, you can go back
                                or <NavLink to={`/fursona-generator`}>click here</NavLink>.
                            </p>
                            <img src={page.image().getCdnImageSrc('chase/assets/chase_confused.png')}
                                 alt="What happened?"
                                 title="I'm sorry. What?"/>
                        </div>
                    </>
                }
            </div>
        );
    }

    return page.generateMetaContent(
        <>
            <ContentBox className="fur-gen-result">
                {isAnimating
                    ?
                        <div className="fur-gen-result-waiting fade-in-out" hidden={!isAnimating}>
                            <img src="/comic/spinner.gif" alt="Thinking..."/>
                        </div>
                    : renderResult()}
            </ContentBox>
        </>
    );
}

export default FursonaGeneratorResultPage;