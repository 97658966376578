import React from "react";
import {NavLink} from "react-router-dom";
import './Title.scss';

interface TitleProps {
    link: string,
    src: string,
    alt: string
}

const Title = (props: TitleProps) => {

    return (
        <div id="header-text">
            <NavLink to={props.link}>
                <img className="noselect" src={props.src} alt={props.alt}/>
            </NavLink>
        </div>
    );
}

export default Title;