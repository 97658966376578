import React, {useState} from "react";
import Color from "../../model/Color";
import './ColorPreview.scss';

interface ColorPreviewProps {
    color: Color
}

const ColorPreview = (props: ColorPreviewProps) => {

    const [showSwatch, setShowSwatch] = useState<boolean>(false);

    const formatName = (name: string): string => {
        return name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
            .join(' ');
    }

    return (
        <>
            <span className="color-preview">
                <span className="color-preview-name"
                      onTouchStart={() => setShowSwatch(true)}
                      onTouchEnd={() => setShowSwatch(false)}>
                    {formatName(props.color.name)}
                </span>
                <div className="color-preview-anchor">
                    <div className="color-preview-swatch">
                        <div className="color-preview-swatch-color"
                              style={{backgroundColor: props.color.hexCode}}>
                            <span className="color-preview-swatch-hexcode">{props.color.hexCode}</span>
                        </div>
                        <div className="color-preview-swatch-indicator"/>
                    </div>
                </div>
            </span>
        </>
    );
}

export default ColorPreview;