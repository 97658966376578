import React from "react";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import ContentBox from "../../../../../components/content-box/ContentBox";
import PageActions from "../../../../base/PageActions";
import '../../../../styles/Common.scss';
import './FanArtSubmissionPage.scss';

interface FanArtSubmissionPageProps {
    dependencies: Dependencies,
    env: Environment
}

const FanArtSubmissionPage = (props: FanArtSubmissionPageProps) => {

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Submit Fan Art - Chase the Fox`,
        description: `Would you like to submit fan art for Chase to use on the site, stream, and other media?`,
        keywords: ["fan art", "submission", "form"],
        imgSrc: `chase/assets/chase_artist.png`,
        url: `gallery/fan-art/submit`
    });

    return page.generateMetaContent(
        <>
            <ContentBox header="Submit Fan Art">
                <p className="fanart-submit-description">
                    Chase is currently taking submissions for fan art! If you would like to have your art displayed
                    in the website gallery, on the stream, or perhaps even featured in future videos, then please
                    use the form below. Thank you for visiting this page and your potential interest in giving
                    to the site!
                </p>
                <p className="fanart-submit-form center">
                    <a href="https://forms.gle/WU8vfQLBJQ1kuD9a8"
                       target="_blank"
                       rel="noopener noreferrer">Fill out the form here!</a>
                </p>
                <img src={page.image().getCdnImageSrc('chase/assets/chase_artist.png')}
                     className="character-bottom center"
                     alt="Furry Artist Chase"
                     title="I'll be doing some art while I wait!"/>
            </ContentBox>
        </>
    );
}

export default FanArtSubmissionPage;