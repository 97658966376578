import React from "react";
import './MediaButton.scss';

interface MediaButtonProps {
    type: 'Twitter' | 'Fur Affinity' | 'Deviant Art' | 'Twitch' | 'YouTube' | 'Tiktok' | 'Telegram' | 'Mastodon' | 'Bluesky',
    username: string,
    size?: 'small' | 'large',
    code?: string,
    hidden?: boolean
}

interface MediaInfo {
    [name: string]: { logo: string, bg: string, link: string }
}

const MediaButton = (props: MediaButtonProps) => {

    const _media_site_info: MediaInfo = {
        Twitter: {logo: 'twitter_logo.png', bg: '#1da1f2', link: 'https://twitter.com/:username'},
        'Fur Affinity': {logo: 'fa_logo.png', bg: '#fab137', link: 'https://www.furaffinity.net/user/:username'},
        'Deviant Art': {logo: 'da_logo.png', bg: '#4e6252', link: 'https://deviantart.com/:username'},
        Twitch: {logo: 'twitch_logo.png', bg: '#6441a5', link: 'https://twitch.tv/:username'},
        YouTube: {logo: 'yt_logo.png', bg: '#ff0000', link: 'https://youtube.com/:handle'},
        Tiktok: {logo: 'tiktok_logo.png', bg: '#ff0050', link: 'https://tiktok.com/@:username'},
        Telegram: {logo: 'telegram_logo.png', bg: '#1da1f2', link: 'https://t.me/:username'},
        Mastodon: {logo: 'mastodon_logo.png', bg: '#1da1f2', link: 'https://:code/@:username'},
        Bluesky: {logo: 'bluesky_logo.png', bg: '#87ceeb', link: 'https://bsky.app/profile/:username.:code'}
    };

    const getBackground = (mediaType: string): string => {
        if (Object.keys(_media_site_info).includes(mediaType)) {
            return _media_site_info[mediaType].bg;
        }

        return '';
    }

    const getLink = (mediaType: string): string => {
        if (Object.keys(_media_site_info).includes(mediaType)) {
            return _media_site_info[mediaType].link;
        }

        return '';
    }

    const getFormattedLink = (): string => {
        let handle: string = '';
        if (props.type === 'YouTube') {
            handle = (props.code) ? "channel/" + props.code : "@" + props.username;
        }

        return getLink(props.type)
            .replace(':username', props.username)
            .replace(':code', props.code || '')
            .replace(':handle', handle || '');
    }

    if (props.size === 'small') {
        return (
            <a className="media-button-small"
               style={{opacity: (props.hidden !== undefined && props.hidden) ? 0 : 100}}
               href={getFormattedLink()}
               target="_blank"
               rel="noopener noreferrer">
                <img src={`https://cdn.chasethefox.com/m/common/social/${_media_site_info[props.type].logo}`}
                     alt={props.type}
                     title={`Check me out on ${props.type}!`}/>
            </a>
        );
    } else {
        return (
            <a className="media-button"
               style={{backgroundColor: getBackground(props.type), opacity: (props.hidden !== undefined && props.hidden) ? 0 : 100}}
               href={getFormattedLink()}
               hidden={props.hidden !== undefined && props.hidden}
               target="_blank"
               rel="noopener noreferrer">
                <img src={`https://cdn.chasethefox.com/m/common/social/${_media_site_info[props.type].logo}`}
                     alt={props.type}
                     title={`Check me out on ${props.type}!`}/>
                <p>
                <span>
                    {(props.type === 'Twitter' || props.type === 'Tiktok') ? '@' : ''}{props.username}
                </span>
                </p>
            </a>
        );
    }
}

export default MediaButton;