import React from "react";
import SpeechBubble from "../../../components/speech-bubble/SpeechBubble";
import ContentBox from "../../../components/content-box/ContentBox";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import './MissingPage.scss';

const MissingPage = () => {
    return (
        <>
            <Helmet>
                <title>404 - Chase the Fox</title>
            </Helmet>
            <ContentBox className="silhouette-missing">
                <div className="page-404">
                    <SpeechBubble text="Hey, there! Looks like the page you tried to visit is missing!"/>
                    <h3 className="side-text">
                        You can use the navigation tool at the top to explore elsewhere on the site
                        or <NavLink to="/">click here to return to the home page!</NavLink>
                    </h3>
                </div>
            </ContentBox>
        </>
    );
}

export default MissingPage;