interface ServerResponse<T> {
    requestId?: string,
    responseCode?: string,
    responseMessage?: string,
    data?: T
}

export const serverResponseSuccessCode = 'CHASE-1000';
export const serverResponseNoData = 'CHASE-0000';

export default ServerResponse;