import React from "react";
import {NavLink} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import PageActions from "../../../../base/PageActions";
import ContentBox from "../../../../../components/content-box/ContentBox";
import '../../../../styles/Common.scss';
import './CommissionTosPage.scss';

interface CommissionTosPageProps {
    dependencies: Dependencies,
    env: Environment
}

const CommissionTosPage = (props: CommissionTosPageProps) => {

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Commission Terms of Service - Chase the Fox`,
        description: `Chase's Terms of Service for art commissions.`,
        keywords: ["tos", "terms of service", "commission", "art commission", "furry commission"],
        imgSrc: `chase/assets/chase_artist.png`,
        url: `commissions/tos`
    });

    return page.generateMetaContent(
        <>
            <ContentBox className="world-content" header="Chase's Terms of Service">
                <ol className="separated-list-elements tos-list">
                    <li>I will do my best to get your commission to you ASAP. I have other projects and personal
                        art that also need to be done, so please keep that in mind. I do not sacrifice quality for
                        speed after all.
                    </li>
                    <li>I reserve the right to decline any request, trade, or commission as I see fit. If I don't
                        feel comfortable drawing your commission, I will not draw it. Examples of such subjects
                        include:
                        <ul>
                            <li>Adult situations and NSFW</li>
                            <li>Extreme violence or gore</li>
                            <li>Racist or other offensive material</li>
                        </ul>
                    </li>
                    <li>Commission forms MUST be filled out in full! This means that name, references, description,
                        etc must all be put in the same place! I will no longer be accepting commissions that say,
                        "I'll DM you the details" or "No name" or "I will send you my references later." Please
                        have all your info ready before submitting the form! No exceptions!
                    </li>
                    <li>For limited openings:
                        <ul>
                            <li>The commission form will remain open for a set length of time (usually about a
                                week or so), but only a select number of slots will be awarded. They will be
                                hand picked by me.
                            </li>
                            <li>You may send as many ideas as you'd like during the same opening.</li>
                            <li>Slots are awarded based on the types of commissions I want to take during the
                                opening, how recently the commissioner received a commission, if the commissioner
                                has not received a commission from me before, and a few other minor factors.
                            </li>
                            <li>If you received a commission in the previous opening, then you are least likely
                                to receive a commission during the current opening.
                            </li>
                        </ul>
                    </li>
                    <li>For full openings:
                        <ul>
                            <li>Commission slots are assigned roughly in the order the form is received. If you
                                decide not to get a commission after your slot is assigned, then you forfeit
                                the slot. If your commission is time-sensitive, then I can discuss an appropriate
                                time to work on it.
                            </li>
                            <li>You may send multiple forms at a time, but only one commission slot may be claimed
                                at a time. If you have a commission in  queue, then you must wait until that
                                commission is complete to claim another.
                            </li>
                            <li>Commissions are generally worked on in order, but there are certain circumstances
                                (time-sensitive commissions, stream schedule, etc) that require me to work on them
                                as needed. If you have a question about when your commission will be worked on,
                                just ask!
                            </li>
                        </ul>
                    </li>
                    <li>Commission prices are subject to change. I usually do a yearly price bump; however, the
                        occasional tweak is necessary from time to time.
                    </li>
                    <li>Payment for a commission can be sent at any time. In order to receive the final artwork,
                        you must complete the payment in full. You may request a preview of the commission at any
                        stage of its creation prior to the completion of  the piece. No refunds are given once the
                        final file is sent.
                    </li>
                    <li>Invoices are sent for payment via your preferred method through my business, Fox Prime
                        Studios LLC.
                    </li>
                    <li>Any commissions greater than US$100 requires at least half of the cost be paid before work
                        can begin.
                    </li>
                    <li>You are free to repost your commission on social media, gallery websites, etc. If you do so,
                        I ask that you please give me credit as the artist.
                    </li>
                    <li>You may not use my art for personal profit. If you want to sell anything regarding my art,
                        then we can discuss this in advance to make a special arrangement.
                    </li>
                    <li>I am not liable for any damages that may occur. (No legal shenanigans please!)</li>
                </ol>
                <p className="center">If you require an exemption or want to discuss any of these terms, please
                    reach out to me! My contact information can be found
                    here: <NavLink to="/contact">Contact Chase!</NavLink>
                </p>
            </ContentBox>
        </>
    );
};

export default CommissionTosPage;