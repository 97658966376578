import React from "react";
import Title from "./title/Title";
import BannerImage from "./banner-image/BannerImage";
import NavigationLinks from "../complex-navigation/NavigationLinks";
import SiteTheme from "../../model/SiteTheme";
import DeviceService from "../../services/DeviceService";
import SchemeToggle from "../scheme-toggle/SchemeToggle";
import './Header.scss';

interface HeaderProps {
    theme: SiteTheme,
    window: DeviceService,
    themeUpdater?: (path: string) => void
}

const Header = (props: HeaderProps) => {

    return (
        <div id="header">
            <Title link={props.theme.navigationLinks['Home'] as string}
                   src={props.theme.bannerTitle}
                   alt={props.theme.bannerTitleAlt}/>
            <BannerImage link={props.theme.navigationLinks['Home'] as string}
                         src={props.theme.bannerImage}
                         alt="Home"
                         circular={props.theme.bannerImageCircular}/>
            <NavigationLinks theme={props.theme} window={props.window} themeUpdater={props.themeUpdater}/>
            <div className="user-data">
                <SchemeToggle window={props.window}/>
            </div>
        </div>
    );
}

export default Header;