import React, { useState, useEffect } from 'react';
import { ThemeProvider } from "styled-components";
import ThemeUtilities from "./utilities/ThemeUtilities";
import SiteRouter from "./routing/SiteRouter";
import Dependencies from "./utilities/Dependencies";
import Environment from "./services/interfaces/Environment";

interface SiteProps {
    deps: Dependencies,
    env: Environment
}

function Site(props: SiteProps) {
    const {theme, themeLoaded} = ThemeUtilities.useTheme(props.deps.deviceService.getBrowserUrl());
    const [selectedTheme, setSelectedTheme] = useState(theme);

    useEffect(() => { setSelectedTheme(theme); }, [theme]);

    return (
        <>{themeLoaded &&
            <ThemeProvider theme={selectedTheme}>
                <ThemeUtilities.GlobalStyles/>
                <SiteRouter deps={props.deps} env={props.env}/>
            </ThemeProvider>
        }</>
    );
}

export default Site;