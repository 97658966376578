import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import SiteTheme from "../../model/SiteTheme";
import DeviceService from "../../services/DeviceService";
import './NavigationLinks.scss';

interface NavigationLinksProps {
    theme: SiteTheme,
    window: DeviceService,
    themeUpdater?: (path: string) => void
}

const NavigationLinks = (props: NavigationLinksProps) => {

    const window: DeviceService = props.window;

    const [hidden, setHidden] = useState<boolean>(true);
    const [mobileContext, setMobileContext] = useState<string[]>([]);

    const updateTheme = (next: string | {[name: string]: string}): void => {
        let path: string = ((typeof next === 'string') ? next as string : Object.values(next).at(0)) || '';
        if (window.getBrowserUrl().includes('cga') === path.includes('cga')) {
            return;
        }

        if (props.themeUpdater) {
            props.themeUpdater(path);
        }
    }

    const generateDesktopNav = (links: {[key: string] : any}): JSX.Element => {
        return <ul className="nav-back">
            {Object.keys(links).map((key, index) => {
                if (typeof links[key] !== 'string') {
                    return <li key={`nav-button-opt-${key}-${index}`}>
                        <span className="nav-button">{key}</span>
                        <div>{generateDesktopNav(links[key])}</div>
                    </li>;
                } else if (!props.theme.fullReloadLinks?.includes(links[key])) {
                    return <li key={`nav-button-${key}-${index}`}>
                        <NavLink to={links[key]}
                                 className="nav-button"
                                 onClick={() => updateTheme(links[key])}>
                            {key}
                        </NavLink>
                    </li>;
                } else {
                    return <li key={`nav-button-${key}-${index}`}>
                        <a href={links[key]} className="nav-button">{key}</a>
                    </li>;
                }
            })}
        </ul>;
    }

    const isHidden = (): boolean => {
        return hidden && !window.isCrawler();
    }

    const switchHidden = (): void => {
       setHidden(!hidden);
    }

    const hideMenu = (): void => {
        setHidden(true);
    }

    const generateMobileNav = (navLinks: {[key: string] : any}): JSX.Element => {
        let links: {[key: string]: any} = navLinks;
        mobileContext.forEach(context => links = links[context]);

        return <ul hidden={isHidden()}>
            {mobileContext.length > 0 &&
                <li className="nav-back" key="mobile-nav-button-back">
                    <button className="nav-button"
                            onClick={() => setMobileContext(mobileContext.slice(0, mobileContext.length - 1))}>
                        Back
                    </button>
                </li>
            }
            {Object.keys(links).map((key, index) =>
                <li className="nav-back" key={`mobile-nav-button-${key}-${index}`}>
                    {typeof links[key] === 'string'
                        ? <NavLink to={links[key]}
                                   className="nav-button"
                                   onClick={() => {
                                       hideMenu();
                                       setMobileContext([]);
                                       updateTheme(links[key]);
                                   }}>
                              {key}
                          </NavLink>
                        : <button className="nav-button"
                                  onClick={() => setMobileContext(mobileContext.concat(key))}>
                            {key}
                        </button>
                    }
                </li>
            )}
        </ul>;
    }

    return (
        <>
            <div className="desktop-navigation">
                {generateDesktopNav(props.theme.navigationLinks)}
            </div>
            <div className="mobile-navigation">
                <button className="hamburger"
                        onClick={() => {setMobileContext([]); switchHidden();}}>
                    Menu
                </button>
                {generateMobileNav(props.theme.navigationLinks)}
            </div>
        </>
    );
}

export default NavigationLinks;