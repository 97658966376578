import React, {ReactElement} from "react";
import {Navigate, Route} from "react-router-dom";

class RedirectsRouter {

    public static getRoutes(): ReactElement {
        return (
            <>
                <Route path="extras">
                    <Route index element={<Navigate replace to="/"/>}/>
                    <Route path="commissions">
                        <Route index element={<Navigate replace to="/commissions"/>}/>
                        <Route path="tos" element={<Navigate replace to="/commissions/tos"/>}/>
                    </Route>
                    <Route path="tf-generator">
                        <Route index element={<Navigate replace to="/tf-generator"/>}/>
                        <Route path="quick-standard" element={<Navigate replace to="/tf-generator/result?quick=true"/>}/>
                        <Route path="quick-all" element={<Navigate replace to="/tf-generator/result?quick=true"/>}/>
                        <Route path="list" element={<Navigate replace to="/tf-generator/list"/>}/>
                    </Route>
                    <Route path="fan-art">
                        <Route index element={<Navigate replace to="/gallery/fan-art/submit"/>}/>
                        <Route path="submit" element={<Navigate replace to="/gallery/fan-art/submit"/>}/>
                    </Route>
                    <Route path="stickers">
                        <Route index element={<Navigate replace to="/telegram-stickers"/>}/>
                        <Route path="install" element={<Navigate replace to="/telegram-stickers/install"/>}/>
                    </Route>
                    <Route path="patch-notes" element={<Navigate replace to="/patch-notes"/>}/>
                    <Route path="tfgen" element={<Navigate replace to="/tf-generator"/>}/>
                    <Route path="fanart" element={<Navigate replace to="/gallery/fan-art/submit"/>}/>
                </Route>
                <Route path="fanart" element={<Navigate replace to="/gallery/fan-art/submit"/>}/>
            </>
        );
    }
}

export default RedirectsRouter;