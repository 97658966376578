import React from "react";
import GymBadgeData from "../gym-badge/GymBadgeData";
import {GymBadgeType} from "../gym-badge/GymBadgeType";
import GymBadge from "../gym-badge/GymBadge";
import "./GymBadgeShowcase.scss";

const MainGymBadgeTypes: GymBadgeType[] = ['fire', 'frost', 'lightning', 'earth',
    'wind', 'water', 'nature', 'spirit',
    'space', 'time', 'light', 'dark'];

interface GymBadgeShowcaseProps {
    badges: GymBadgeData[]
}

const GymBadgeShowcase = (props: GymBadgeShowcaseProps) => {

    const getBadgeSlot = (badge?: GymBadgeData): JSX.Element => {
        if (!badge) {
            return <span className="badge-empty-slot"></span>;
        }

        return <GymBadge username={badge.username}
                         badge={badge.badge}
                         level={badge.level}
                         awardDate={badge.awardDate}/>;
    }

    const getMainBadge = (typeName: GymBadgeType): JSX.Element => {
        return getBadgeSlot(props.badges.find((badge) => badge.badge === typeName));
    }

    const getAuxiliaryBadge = (index: number): JSX.Element => {
        let auxiliaryBadges = props.badges.filter(badge => !MainGymBadgeTypes.includes(badge.badge));
        let badge = (index < auxiliaryBadges.length) ? auxiliaryBadges[index] : undefined;

        return getBadgeSlot(badge);
    }

    return (
        <>
            <div className="badge-showcase">
                <div className="badges-box-border badges-auxiliary-1">
                    <div>{getAuxiliaryBadge(0)}</div>
                    <div>{getAuxiliaryBadge(1)}</div>
                    <div>{getAuxiliaryBadge(2)}</div>
                    <div>{getAuxiliaryBadge(3)}</div>
                </div>
                <div className="badges-box-border badges-main">
                    <div className="badge-row">
                        <div className="fire-to-frost">{getMainBadge('fire')}</div>
                        <div>{getMainBadge('frost')}</div>
                        <div className="wind-to-water">{getMainBadge('wind')}</div>
                        <div>{getMainBadge('water')}</div>
                    </div>
                    <div className="badge-row">
                        <div className="fire-to-lightning">{getMainBadge('lightning')}</div>
                        <div className="space-to-time">{getMainBadge('space')}</div>
                        <div className="time-to-dark">{getMainBadge('time')}</div>
                        <div className="water-to-earth">{getMainBadge('earth')}</div>
                    </div>
                    <div className="badge-row">
                        <div><span className="badge-hidden-slot"></span></div>
                        <div className="space-to-light">{getMainBadge('light')}</div>
                        <div className="light-to-dark">{getMainBadge('dark')}</div>
                        <div><span className="badge-hidden-slot"></span></div>
                    </div>
                    <div className="badge-row">
                        <div><span className="badge-hidden-slot"></span></div>
                        <div className="nature-to-spirit">{getMainBadge('nature')}</div>
                        <div>{getMainBadge('spirit')}</div>
                        <div><span className="badge-hidden-slot"></span></div>
                    </div>
                </div>
                <div className="badges-box-border badges-auxiliary-2">
                    <div>{getAuxiliaryBadge(4)}</div>
                    <div>{getAuxiliaryBadge(5)}</div>
                    <div>{getAuxiliaryBadge(6)}</div>
                    <div>{getAuxiliaryBadge(7)}</div>
                </div>
            </div>
        </>
    );
}

export default GymBadgeShowcase;