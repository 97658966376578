import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Dependencies from "../../../../utilities/Dependencies";
import CharacterOption from "../../../../model/CharacterOption";
import ImageLink from "../../../../components/image-link/ImageLink";
import Environment from "../../../../services/interfaces/Environment";
import ContentBox from "../../../../components/content-box/ContentBox";
import PageActions from "../../../base/PageActions";
import '../../../styles/Common.scss';
import './CharactersPage.scss';

interface CharactersPageProps {
    dependencies: Dependencies,
    env: Environment
}

const CharactersPage = (props: CharactersPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Characters - Chase the Fox`,
        description: `Meet Chase and his colorful cast of cartoony characters!`,
        keywords: ["furry oc", "character", "casarok", "anthropomorphic", "toon"],
        imgSrc: `chase/assets/chase_full.png`,
        url: `characters`
    }), [props]);

    const [hasFetchedList, setFetchedList] = useState<boolean>(false);

    const navigateTo = useNavigate();

    const list = useRef<CharacterOption[]>();

    useEffect(() => {
        page.http().getCharacterOptions()
            .then(response => {
                if (!response || response.length === 0) {
                    list.current = [];
                } else {
                    list.current = response;
                }
            })
            .catch(() => navigateTo("/error", {replace: false}))
            .finally(() => setFetchedList(true));
    }, []);

    return page.generateMetaContent(
        <>
            <ContentBox header="Chase's world has a colorful cast of cartoony characters! Click on one to learn more about them.">
                <h3 className="headline">Protagonists</h3>
                <div className="character-lineup">
                    {hasFetchedList && list.current?.filter(option => option.category === 'PRESENT' && option.alignment === 'PROTAGONIST')
                        .map(option =>
                            <ImageLink
                                key={`character-present-${option.shortName}`}
                                imgSrc={page.image().getCdnImageSrc(`chase/characters/${option.shortName}/1.png`)}
                                linkUrl={option.shortName}
                                linkName={`${page.naming().capitalizeName(option.shortName)}`}/>
                    )}
                </div>
                <h3 className="headline">Antagonists</h3>
                <div className="character-lineup">
                    {hasFetchedList && list.current?.filter(option => option.category === 'PRESENT' && option.alignment === 'ANTAGONIST')
                        .map(option =>
                            <ImageLink
                                key={`character-present-${option.shortName}`}
                                imgSrc={page.image().getCdnImageSrc(`chase/characters/${option.shortName}/1.png`)}
                                linkUrl={option.shortName}
                                linkName={`${page.naming().capitalizeName(option.shortName)}`}/>
                    )}
                </div>
                <h3 className="headline">Neutral/Not Yet Introduced</h3>
                <div className="character-lineup">
                    {hasFetchedList && list.current?.filter(option => option.category === 'PRESENT' && !option.alignment)
                        .map(option =>
                            <ImageLink
                                key={`character-present-${option.shortName}`}
                                imgSrc={page.image().getCdnImageSrc(`chase/characters/${option.shortName}/1.png`)}
                                linkUrl={option.shortName}
                                linkName={`${page.naming().capitalizeName(option.shortName)}`}/>
                    )}
                </div>
                <h3 className="headline">Characters From the Past of Casarok</h3>
                <div className="character-lineup">
                    {hasFetchedList && list.current?.filter(option => option.category === 'PAST')
                        .map(option =>
                            <ImageLink
                                key={`character-past-${option.shortName}`}
                                imgSrc={page.image().getCdnImageSrc(`chase/characters/${option.shortName}/1.png`)}
                                linkUrl={option.shortName}
                                linkName={`${page.naming().capitalizeName(option.shortName)}`}/>
                    )}
                </div>
            </ContentBox>
        </>
    );
}

export default CharactersPage;