import React from "react";
import './SpeechBubble.scss';

interface SpeechBubbleProps {
    text: string,
    tail?: 'left' | 'right' | 'middle'
}

const SpeechBubble = (props: SpeechBubbleProps) => {

    const getTailLocation = (): 'left' | 'right' | 'middle' => {
        if (props.tail) {
            return props.tail;
        }

        return 'right';
    }

    return (
        <div className={`speech-bubble tail-${getTailLocation()}`}>
            <div>
                <span>{props.text}</span>
            </div>
        </div>
    );
}

export default SpeechBubble;