import React from "react";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import SpeechBubble from "../../../components/speech-bubble/SpeechBubble";
import ContentBox from "../../../components/content-box/ContentBox";
import './CgaErrorPage.scss';

const CgaErrorPage = () => {

    return (
        <>
            <Helmet>
                <title>Error - Caudalglen Guild of Adventurers</title>
            </Helmet>
            <ContentBox className="aerilyn-page-error" design="scroll">
                <div className="page-500">
                    <SpeechBubble text="It appears we've run into a problem. Let's backtrack!"/>
                    <h3 className="side-text">
                        It looks like an error occurred. Could you
                        please <NavLink to="/cga/contact">contact Chase</NavLink> and let him know what happened?
                        If you need to get back to the home page, <NavLink to="/cga">you can click here!</NavLink>
                    </h3>
                </div>
            </ContentBox>
        </>
    );
}

export default CgaErrorPage;