import React from "react";
import './ComicControls.scss';
import {NavLink} from "react-router-dom";

interface ComicControlsProps {
    currentPage: number,
    maxPage: number,
    goTo: (page: number) => void
}

const ComicControls = (props: ComicControlsProps) => {

    return (
        <div className="comic-controls">
            <ul>
                <li>
                    {(props.currentPage > 1)
                        ? <NavLink className="active-button" to={`../1`} onClick={() => props.goTo(1)}>
                            <img src="/comic/nav/first.png" alt="First" title="Go to First Page"/>
                        </NavLink>
                        : <img className="disabled-button" src="/comic/nav/first.png" alt="First" title="Go to First Page"/>}
                </li>
                <li>
                    {(props.currentPage > 1)
                        ? <NavLink className="active-button" to={`../${props.currentPage - 1}`} onClick={() => props.goTo(props.currentPage - 1)}>
                            <img src="/comic/nav/back.png" alt="Previous" title="Go to Previous Page"/>
                        </NavLink>
                        : <img className="disabled-button" src="/comic/nav/back.png" alt="Previous" title="Go to Previous Page"/>}
                </li>
                <li>
                    {(props.currentPage < props.maxPage)
                        ? <NavLink className="active-button" to={`../${props.currentPage + 1}`} onClick={() => props.goTo(props.currentPage + 1)}>
                            <img src="/comic/nav/forward.png" alt="Next" title="Go to Next Page"/>
                        </NavLink>
                        : <img className="disabled-button" src="/comic/nav/forward.png" alt="Next" title="Go to Next Page"/>}
                </li>
                <li>
                    {(props.currentPage < props.maxPage)
                        ? <NavLink className="active-button" to={`../${props.maxPage}`} onClick={() => props.goTo(props.maxPage)}>
                            <img src="/comic/nav/latest.png" alt="Latest" title="Go to Latest Page"/>
                        </NavLink>
                        : <img className="disabled-button" src="/comic/nav/latest.png" alt="Latest" title="Go to Latest Page"/>}
                </li>
            </ul>
        </div>
    );
}

export default ComicControls;