import {configureStore, createSlice, PayloadAction} from "@reduxjs/toolkit";
import TfGenQuery from "../model/TfGenQuery";
import {GeneratorFlag} from "../model/generator/GeneratorTypes";

const _tf_gen_default_state: TfGenQuery = {
    category: 'ALL',
    extinct: 'INCLUDE',
    mythical: 'INCLUDE',
    casarokanUnique: 'EXCLUDE',
    modifier: 'NONE'
};

const _tf_gen_slice = createSlice({
    name: 'tf-generator',
    initialState: _tf_gen_default_state as TfGenQuery,
    reducers: {
        updateCategory: (store: TfGenQuery, action: PayloadAction<string>) => { return {...store, category: action.payload} },
        updateExtinct: (store: TfGenQuery, action: PayloadAction<GeneratorFlag>) => { return {...store, extinct: action.payload} },
        updateMythical: (store: TfGenQuery, action: PayloadAction<GeneratorFlag>) => { return {...store, mythical: action.payload} },
        updateCasarokan: (store: TfGenQuery, action: PayloadAction<GeneratorFlag>) => { return {...store, casarokanUnique: action.payload} },
        updateModifier: (store: TfGenQuery, action: PayloadAction<string>) => { return {...store, modifier: action.payload} },
        reset: () => { return {..._tf_gen_default_state} }
    }
});

const _tf_gen_store = configureStore({
    reducer: _tf_gen_slice.reducer
});

export const TfGenActions = _tf_gen_slice.actions;
export default _tf_gen_store;