import React, {useEffect, useState} from "react";
import {Params, useNavigate, useParams} from "react-router-dom";
import Dependencies from "../../../../../../utilities/Dependencies";
import Environment from "../../../../../../services/interfaces/Environment";
import GymBadgeShowcase from "../../../../../../components/gym-badge-showcase/GymBadgeShowcase";
import GymBadgeData from "../../../../../../components/gym-badge/GymBadgeData";
import PageActions from "../../../../../base/PageActions";
import ContentBox from "../../../../../../components/content-box/ContentBox";
import "../../../../../styles/Common.scss";
import "./PokemonPlayerPage.scss";

interface PokemonPlayerPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface PokemonPlayerPageState {
    badges: GymBadgeData[]
}

const PokemonPlayerPage = (props: PokemonPlayerPageProps) => {

    const {player}: Readonly<Params> = useParams();

    const [state, setState] = useState<PokemonPlayerPageState>({
        badges: []
    });

    const navigateTo = useNavigate();

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `${player ? player + '\'s Gym Challenge Progress' : 'Casarokan Gym Challenge'} - Chase the Fox`,
        description: player ? `Check out ${player}'s progress in Chase's gym challenge!`
            : "Learn about Chase's in-stream gym challenge and check player badge progress!",
        keywords: ["twitch", "pokemon", "gym challenge", "chasetheredfox",
            player ? player.toLowerCase() : "stream info"],
        imgSrc: `chase/stream/pokemon/gym_logo.png`,
        url: `stream/pokemon/gym-challenge${player && '/players/' + player}`
    });

    useEffect(() => {
        if (!player || player.trim().length === 0) {
            navigateTo("/stream/pokemon/gym-challenge", {replace: true});
        } else {
            page.http().getPokemonPlayerBadges(player)
                .then(badges => {
                    if (badges && badges.length > 0) {
                        setState({...state, badges});
                    } else {
                        navigateTo("/404", {replace: true});
                    }
                })
                .catch(() => navigateTo("/404", {replace: true}));
        }
    }, [player]);

    return page.generateMetaContent(
        <>
            <ContentBox header={`Gym Challenge Progress For ${player}`}>
                <div>
                    <p className="center">
                        Hover or tap and hold on a badge to get more details!
                    </p>
                    <GymBadgeShowcase badges={state.badges}/>
                </div>
            </ContentBox>
        </>
    );
}

export default PokemonPlayerPage;