import React, {ReactElement} from "react";
import {Navigate, Route} from "react-router-dom";
import AdventurersHome from "../../pages/cga/home/AdventurersHome";
import Dependencies from "../../utilities/Dependencies";
import Environment from "../../services/interfaces/Environment";
import AdventurersCharactersPage from "../../pages/cga/character/AdventurersCharactersPage";
import AdventurersCharacterDisplayPage from "../../pages/cga/character/display/AdventurersCharacterDisplayPage";
import AdventurersContactPage from "../../pages/cga/contact/AdventurersContactPage";
import CgaErrorPage from "../../pages/cga/500/CgaErrorPage";
import CgaMissingPage from "../../pages/cga/400/CgaMissingPage";

class AdventurersRouter {

    public static getRoutes(baseRoute: string, deps: Dependencies, env: Environment): ReactElement {
        return (
            <Route path={baseRoute}>
                <Route index element={<AdventurersHome dependencies={deps} env={env}/>}/>
                <Route path="home" element={<Navigate replace to={baseRoute}/>}/>
                <Route path="characters">
                    <Route index element={<AdventurersCharactersPage dependencies={deps} env={env}/>}/>
                    <Route path=":name" element={<AdventurersCharacterDisplayPage dependencies={deps} env={env}/>}/>
                </Route>
                <Route path="contact">
                    <Route index element={<AdventurersContactPage dependencies={deps} env={env}/>}/>
                </Route>
                <Route path="error" element={<CgaErrorPage/>}/>
                <Route path="404" element={<CgaMissingPage/>}/>
                <Route path="*" element={<Navigate replace to={`${baseRoute}/404`}/>}/>
            </Route>
        );
    }
}

export default AdventurersRouter;