import React, {useEffect, useMemo, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import ContentBox from "../../../../../components/content-box/ContentBox";
import PageActions from "../../../../base/PageActions";
import "../../../../styles/Common.scss";
import "./PokemonGymStreamPage.scss";

interface PokemonGymStreamPageProps {
    dependencies: Dependencies,
    env: Environment
}

const PokemonGymStreamPage = (props: PokemonGymStreamPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Casarokan Gym Challenge - Chase the Fox`,
        description: "Learn about Chase's in-stream gym challenge and see those who have played!",
        keywords: ["twitch", "stream info", "pokemon", "gym challenge", "chasetheredfox"],
        imgSrc: `chase/stream/pokemon/gym_logo.png`,
        url: `stream/pokemon/gym-challenge`
    }), [props]);

    const [hasFetchedResult, setFetchedResult] = useState<boolean>(false);

    const players = useRef<string[]>([]);

    useEffect(() => {
        page.http().getPokemonPlayerNames()
            .then(response => players.current = response)
            .finally(() => setFetchedResult(true));
    });

    return page.generateMetaContent(
        <>
            <ContentBox header="Be the very best and take the Casarokan Gym Challenge!">
                <div>
                    <img src={page.image().getCdnImageSrc('chase/stream/pokemon/gym_logo.png')}
                         alt="The Casarokan Gym Challenge logo, stylized to represent the 3 starter types"
                         title="Will you accept the challenge?"
                         className="width-30-percent block"/>
                    <p>
                        The Casarokan Gym Challenge is set up by me as a fun way to interact with viewers and give
                        folks a decent challenge catered to their skill level. There are 8 gyms in total, and each
                        one is set up with a different element and theme. The lowest level is played like a normal
                        gym progression and skill check while the highest level provides the utmost challenge to
                        competitive players.
                    </p>
                    <p>
                        Rules:
                    </p>
                    <ol>
                        <li>Gyms may be challenged as many times as you'd like until you have defeated them.
                            Challenges may only be undertaken during Pokemon Scarlet/Violet streams. The first 3 gyms
                            are available for all, but later gyms can only be unlocked after defeating a certain number
                            of other gyms first!
                        </li>
                        <li>All Pokemon are set to level 50 for this challenge.</li>
                        <li>All Pokemon are set up with competitive builds, movesets, and items, growing in
                            difficulty and viability in later gyms.</li>
                        <li>If the battle timer runs out, the match is forfeited by the challenger.</li>
                        <li>Stream rules apply for this battle. If the challenger uses offensive or vulgar names
                            for their Pokemon, they are subject to forfeit and ban at my discretion.</li>
                        <li>Each gym has a bronze, silver, and gold challenge. You may pick which level you'd
                            like to challenge when selecting a gym.
                            <ul>
                                <li>For the bronze challenge, the gym leader will only use the first 4 Pokemon on the
                                    team. They will also be sent out in a set order with no switching unless a
                                    Pokemon is KO'd.</li>
                                <li>For the silver challenge, the gym leader team will use 5 Pokemon and cannot switch
                                    except on a KO, but the order is not set except for the final Pokemon.</li>
                                <li>For the gold challenge, the gym leader uses all 6 Pokemon. The match will play out
                                    as if it is a normal singles format battle except for the final Pokemon.</li>
                                <li>No matter the star rating, the terastallized Pokemon will always be used as
                                    the final one.</li>
                                <li>If the final Pokemon is forced out (such as through Roar or Whirlwind),
                                    then the match is forfeited by the challenger.</li>
                                <li>No other restrictions are set on the challenger. They may use any Pokemon or items
                                    to achieve victory as long as it does not conflict with another rule here.</li>
                            </ul>
                        </li>
                        <li>Upon defeating a gym, you will be credited here with that gym's badge and star
                            rating.</li>
                        <li>To take on the Divine Elite Four Challenge of a particular rating, you must defeat
                            all 8 gyms of that same rating.
                            <ul>
                                <li>The same rules apply except the bronze challenge will use 5 Pokemon instead
                                    of 4.</li>
                            </ul>
                        </li>
                    </ol>
                    <p>
                        To check your current gym badges, click on your Twitch handle in the list below:
                    </p>
                    <div className="pokemon-player-list">
                        <ul>
                            {hasFetchedResult && players.current.map((name, index) => {
                                return <li key={`pokemon-player-${name}-${index}`}>
                                    <NavLink to={`players/${name}`}>{name}</NavLink>
                                </li>;
                            })}
                        </ul>
                    </div>
                </div>
            </ContentBox>
        </>
    );
}

export default PokemonGymStreamPage;