import React, {useMemo} from "react";
import {NavLink} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import PageActions from "../../../../base/PageActions";
import '../../../../styles/Common.scss';
import '../WorldPages.scss';
import './WorldLanguagesPage.scss';

interface WorldLanguagesPageProps {
    dependencies: Dependencies,
    env: Environment
}

const WorldLanguagesPage = (props: WorldLanguagesPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Casarok: Languages - Chase the Fox`,
        description: `Languages in Chase's world are similar to those on Earth, but differ based on their history.`,
        keywords: ["casarok", "chase's world", "language", "fantasy languages", "fictional languages"],
        imgSrc: `chase/world/languages/preview.png`,
        url: `world/languages`
    }), [props]);

    return page.generateMetaContent(
        <>
            <div className="world-book" key={`world-languages`}>
                <div className="world-navigation">
                    <NavLink to={`/world`} className="world-link glowing block">World</NavLink>
                    <span>&nbsp;&gt;&nbsp;</span>
                    <span className="world-link block">Languages</span>
                </div>
                <div id="page-left" className="world-book-page">
                    <h2 className="center">Casarokan Languages</h2>
                    <p>
                        The gods and goddesses of the world gifted the early peoples of the planet with the various
                        languages spoken through its history. All of them underwent massive change and reorganization
                        throughout the millennia. The origins of them have been lost for centuries to the Great
                        Cataclysm, but they would not sound entirely foreign if spoken in the modern day. Archaeological
                        digs continue to uncover texts from the era lost before the Cataclysm occurred, and they are
                        capable of being translated by skilled experts in the field.
                    </p>
                    <p>
                        Following are a list of languages that are prominent in the world of Casarok. Some of them
                        include explanations on how they are written and spoken. More languages will potentially be
                        expanded upon in the future as well.
                    </p>
                    <img className="language-opener-image"
                         src={page.image().getCdnImageSrc('chase/world/languages/preview.png')}
                         alt="Snow saying something in Common"
                         title="Just asking a simple question in Common!"/>
                </div>
                <div id="page-right" className="world-book-page">
                    <ul className="separated-list-elements">
                        <li>
                            <NavLink to="common" className="world-link glowing">Common</NavLink>:
                            The most prominent language in modern times. It was developed as a way to unite a
                            pre-Cataclysmic world and break barriers for purposes of trade, diplomacy, and the like.
                        </li>
                        <li>
                            <strong>Dracammarum</strong>:
                            Lingua Dracammarum (known mostly as only "Dracammarum") is the language of
                            the <NavLink to="/world/bestiary/draconic/dracamma" className="world-link glowing">Dracamma
                            species</NavLink> and translates directly as such. It is derived from Latin and was the
                            most spoken language in the world prior to the adoption of Common.
                        </li>
                        <li>
                            <strong>Drekaldrish</strong>:
                            The language spoken by
                            the <NavLink to="/world/bestiary/draconic/drekaldr" className="world-link glowing">
                                Drekaldar
                            </NavLink> for centuries, based on Old Norse. This language is mostly lost to time as few
                            members of this Draconic species have integrated into modern life, and as such most of the
                            documentation for the tongue is forgotten outside the fabled Great Library.
                        </li>
                        <li>
                            <strong>Sylvan</strong>:
                            The language of choice for the
                            the <NavLink to="/world/bestiary/draconic/draicinne" className="world-link glowing">
                                Draicinni
                            </NavLink> which arose from a form of Old Irish. Many other forest dwellers and those
                            closely associated with the Draconic species adopted this as their primary method of speech
                            until Common was brought to the innermost woods of the world.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default WorldLanguagesPage;