import React, {ReactElement} from "react";
import {Route} from "react-router-dom";
import Dependencies from "../../utilities/Dependencies";
import Environment from "../../services/interfaces/Environment";
import TfGeneratorPage from "../../pages/chase/fun/tf-generator/TfGeneratorPage";
import TfGeneratorResultPage from "../../pages/chase/fun/tf-generator/result/TfGeneratorResultPage";
import TfGeneratorListPage from "../../pages/chase/fun/tf-generator/list/TfGeneratorListPage";
import FursonaGeneratorPage from "../../pages/chase/fun/fursona-generator/FursonaGeneratorPage";
import FursonaGeneratorResultPage from "../../pages/chase/fun/fursona-generator/result/FursonaGeneratorResultPage";
import FursonaGeneratorListPage from "../../pages/chase/fun/fursona-generator/list/FursonaGeneratorListPage";

class FunRouter {

    public static getRoutes(deps: Dependencies, env: Environment): ReactElement {
        return (
            <>
                <Route path="fursona-generator">
                    <Route index element={<FursonaGeneratorPage dependencies={deps} env={env}/>}/>
                    <Route path="result" element={<FursonaGeneratorResultPage dependencies={deps} env={env}/>}/>
                    <Route path="list" element={<FursonaGeneratorListPage dependencies={deps} env={env}/>}/>
                </Route>
                <Route path="tf-generator">
                    <Route index element={<TfGeneratorPage dependencies={deps} env={env}/>}/>
                    <Route path="result" element={<TfGeneratorResultPage dependencies={deps} env={env}/>}/>
                    <Route path="list" element={<TfGeneratorListPage dependencies={deps} env={env}/>}/>
                </Route>
            </>
        );
    }
}

export default FunRouter;