import React, {useEffect, useMemo, useRef, useState} from "react";
import {NavLink, Params, useLocation, useNavigate, useParams} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import PageActions from "../../../../base/PageActions";
import '../../../../styles/Common.scss';
import '../WorldPages.scss';
import '../WorldDynamicContent.scss';

interface WorldSectionPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface WorldSectionPageResult {
    contentLeft: string[],
    contentRight: string[],
    cardImageSrc?: string
}

interface WorldSectionPageParams extends Params {
    section: string,
    subsection?: string,
    topic?: string
}

const WorldSectionPage = (props: WorldSectionPageProps) => {

    const params = useParams<WorldSectionPageParams>();
    const [currentPath, setCurrentPath ] = useState<string>(params.section!
        + (params.subsection ? `/${params.subsection}` : '') + (params.topic ? `/${params.topic}` : ''));

    const result = useRef<WorldSectionPageResult>({
        contentLeft: [],
        contentRight: []
    });

    const getDescription = (): string => {
        if (params.topic) {
            return `Learn about ${props.dependencies.namingService.getSectionProperName(params.topic)} in the Casarok 
            ${props.dependencies.namingService.getSectionProperName(params.section!)} regarding Chase's world!`;
        } else if (params.subsection) {
            return `Learn about ${props.dependencies.namingService.getSectionProperName(params.subsection)} in the Casarok 
            ${props.dependencies.namingService.getSectionProperName(params.section!)} regarding Chase's world!`;
        } else {
            return `The Casarok ${props.dependencies.namingService.getSectionProperName(params.section!)} section, 
            detailing information about Chase's world!`;
        }
    }

    const getKeywords = (): string[] => {
        let keywords = ["casarok", "chase's world", props.dependencies.namingService.getKeywordName(params.section!)];
        if (params.subsection) {
            keywords = keywords.concat(props.dependencies.namingService.getKeywordName(params.subsection))
        }
        if (params.topic) {
            keywords = keywords.concat(props.dependencies.namingService.getKeywordName(params.topic));
        }

        return keywords;
    }

    const getTitleSubsection = (): string => {
        if (params.topic) {
            return ` (${props.dependencies.namingService.getSectionProperName(params.topic)})`;
        } else if (params.subsection) {
            return ` (${props.dependencies.namingService.getSectionProperName(params.subsection)})`;
        } else {
            return '';
        }
    }

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Casarok: ${props.dependencies.namingService.getSectionProperName(params.section!)}${getTitleSubsection()} - Chase the Fox`,
        description: getDescription(),
        keywords: getKeywords(),
        imgSrc: (result.current && result.current.cardImageSrc) ? result.current.cardImageSrc : `world/${currentPath}/preview.png`,
        url: `chase/world/${currentPath}`
    }), [props, currentPath]);

    const [hasFetchedResult, setFetchedResult] = useState<boolean>(false);

    const navigateTo = useNavigate();
    const location = useLocation();

    useEffect(() => {
        page.http().getWorldContentByPath(currentPath)
            .then(response => {
                if (!response) {
                    navigateTo("/error", {replace: true});
                } else {
                    result.current = {
                        contentLeft: response.pageLeft || [],
                        contentRight: response.pageRight || []
                    };
                }
            })
            .catch(() => navigateTo("/error", {replace: true}))
            .finally(() => setFetchedResult(true));
    }, [currentPath]);

    const navigateToPage = (): void => {
        let path = location.pathname;
        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }

        if (path.startsWith('world/') || path.startsWith('/world/')) {
            setCurrentPath(path.substring(path.lastIndexOf('world/') + 'world/'.length));
            setFetchedResult(false);
        } else {
            navigateTo(path, {replace: false});
        }

        page.device().goToTop();
    }

    const getPageDivisions = (): string[] => {
        return currentPath.split('/');
    }

    const getCornerNavigationPath = (): JSX.Element => {
        return (
            <>
                <NavLink to={`/world`} className="world-link glowing block">World</NavLink>
                {getPageDivisions().map((division, index) =>
                    (<React.Fragment key={`world-nav-${index}`}>
                        <span>&gt;</span>
                        {(index < getPageDivisions().length - 1)
                            ? <NavLink to={`/world/${getPageDivisions().slice(0, index + 1).join('/')}`}
                                       className="world-link glowing block"
                                       onClick={() => navigateToPage()}>
                                {page.naming().getSectionProperName(division)}
                            </NavLink>
                            : <span className="world-link block">{page.naming().getSectionProperName(division)}</span>}
                    </React.Fragment>)
                )}
            </>
        );
    }

    const switchVisible = (): void => {
        let initiallyVisibleTarget: HTMLElement = document.getElementById('visible-element')!;
        let initiallyHiddenTarget: HTMLElement = document.getElementById('hidden-element')!;

        if (initiallyVisibleTarget.className.includes('visible')) {
            initiallyVisibleTarget.classList.add('switch-hidden');
            initiallyVisibleTarget.classList.remove('switch-visible');
            initiallyHiddenTarget.classList.add('switch-visible');
            initiallyHiddenTarget.classList.remove('switch-hidden');
        } else {
            initiallyHiddenTarget.classList.add('switch-hidden');
            initiallyHiddenTarget.classList.remove('switch-visible');
            initiallyVisibleTarget.classList.add('switch-visible');
            initiallyVisibleTarget.classList.remove('switch-hidden');
        }
    }

    return page.generateMetaContent(
        <>
            <div className="world-book" key={`world-${getPageDivisions().join('-')}`}>
                <div className="world-navigation">{getCornerNavigationPath()}</div>
                <div id="page-left" className="world-book-page">
                    {hasFetchedResult && page.html().parse(result.current.contentLeft.join('\n'),
                        () => navigateToPage(),
                        () => switchVisible()
                    )}
                </div>
                <div id="page-right" className="world-book-page">
                    {hasFetchedResult && page.html().parse(result.current.contentRight.join('\n'),
                        () => navigateToPage(),
                        () => switchVisible())}
                </div>
            </div>
        </>
    );
}

export default WorldSectionPage;