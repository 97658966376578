import React, {useEffect, useMemo, useRef, useState} from "react";
import {Params, useLocation, useNavigate, useParams} from "react-router-dom";
import SpeechBubble from "../../../../../components/speech-bubble/SpeechBubble";
import CharacterData from "../../../../../model/CharacterData";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import ContentBox from "../../../../../components/content-box/ContentBox";
import '../../../../styles/Common.scss';
import './CharacterDisplayPage.scss';
import PageActions from "../../../../base/PageActions";

interface CharacterDisplayPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface CharacterDisplayPageParams extends Params {
    name: string
}

const CharacterDisplayPage = (props: CharacterDisplayPageProps) => {
    
    const params = useParams<CharacterDisplayPageParams>();
    const [name, setName] = useState<string>(params.name!);
    
    const character = useRef<CharacterData>();

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Meet ${props.dependencies.namingService.capitalizeName(name)} - Chase the Fox`,
        description: `Meet ${props.dependencies.namingService.capitalizeName(name)}, a denizen of the world of Casarok.`,
        keywords: ["furry oc", "character", "casarok", "toon", name],
        imgSrc: `chase/characters/${name}/1.png`,
        url: `characters/${name}`
    }), [props, name]);

    const [hasFetchedResult, setFetchedResult] = useState<boolean>(false);

    const navigateTo = useNavigate();
    const location = useLocation();

    useEffect(() => {
        page.http().getCharacterData(name)
            .then(response => {
                if (!response || Object.keys(response).length === 0 || response.category.includes('GUILD')) {
                    navigateTo("/error", {replace: true});
                } else {
                    character.current = response;
                }
            })
            .catch(() => navigateTo("/error", {replace: true}))
            .finally(() => setFetchedResult(true));
    }, [name]);
    
    const navigateToLocation = () => {
        let path = location.pathname;
        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }

        if (path.startsWith('characters/') || path.startsWith('/characters/')) {
            setName(path.substring(path.lastIndexOf('characters/') + 'characters/'.length));
            setFetchedResult(false);
        } else {
            navigateTo(path, {replace: false});
        }

        page.device().goToTop();
    };

    return page.generateMetaContent(
        <>
            <ContentBox>
                {(!hasFetchedResult || !character.current) ? <></> :
                    <>
                        <SpeechBubble text={character.current?.quote || ''} tail={page.device().isMobileWidth() ? 'middle' : 'right'}/>
                        <div className="character-info">
                            <div className="character-image character-image-right">
                                <img
                                    src={page.image().getCdnImageSrc(`chase/characters/${name}/1.png`)}
                                    alt={page.naming().capitalizeName(name)}
                                    className="character"/>
                            </div>
                            <p>
                                <strong>Full name: </strong><span>{character.current?.fullName}</span><br/>
                                {(character.current?.otherNames)
                                    ? <><strong>Other names: </strong>
                                        <span>{page.html().parse(character.current?.otherNames || '', () => navigateToLocation())}</span><br/></>
                                    : <></>}
                                <strong>Species: </strong><span>{page.html().parse(character.current?.species || '', () => navigateToLocation())}</span> (<em>{page.html().parse(character.current?.speciesScientificName || '', () => navigateToLocation())}</em>)<br/>
                                <strong>Gender: </strong> <span>{character.current?.gender}</span><br/>
                                {(character.current?.category === 'PRESENT')
                                    ? <><strong>Age: </strong> <span>{character.current?.lifetime}</span><br/></>
                                    : <><strong>Lifetime: </strong> <span>{character.current?.lifetime}</span><br/></>}
                                <strong>Height: </strong><span>{character.current?.heightUs}</span> (<span>{character.current?.heightMetric}</span>)<br/>
                                <strong>Occupation: </strong> <span>{page.html().parse(character.current?.occupation || '', () => navigateToLocation())}</span><br/>
                                <strong>Favorite Color: </strong> <span>{character.current?.favoriteColor}</span><br/>
                                <strong>Favorite Food: </strong> <span>{page.html().parse(character.current?.favoriteFood || '', () => navigateToLocation())}</span><br/>
                                <strong>Likes: </strong> <span>{page.html().parse(character.current?.likes || '', () => navigateToLocation())}</span><br/>
                                <strong>Dislikes: </strong> <span>{page.html().parse(character.current?.dislikes || '', () => navigateToLocation())}</span>
                            </p>
                            <p>
                                <strong>Character Traits: </strong>
                                <span>{page.html().parse(character.current?.traits || '', () => navigateToLocation())}</span>
                            </p>
                            <p>
                                <strong>Abilities: </strong>
                                <span>{page.html().parse(character.current?.abilities || '', () => navigateToLocation())}</span>
                            </p>
                            <p>
                                <strong>Skills: </strong>
                                <span>{page.html().parse(character.current?.skills || '', () => navigateToLocation())}</span>
                            </p>
                            <p>
                                <strong>{(character.current?.category === 'PRESENT') ? 'Background: ' : 'Story: '}</strong>
                                <span>{page.html().parse(character.current?.story1 || '', () => navigateToLocation())}</span>
                            </p>
                            <div className="character-image-left">
                                <img
                                    src={page.image().getCdnImageSrc(`chase/characters/${name}/2.png`)}
                                    alt={page.naming().capitalizeName(name)}
                                    className="character"/>
                            </div>
                            <div>
                                <span>{page.html().parse(character.current?.story2 || '', () => navigateToLocation())}</span>
                            </div>
                        </div>
                    </>}
            </ContentBox>
        </>
    );
}

export default CharacterDisplayPage;