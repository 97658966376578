import HttpClient from "../services/interfaces/HttpClient";
import axios, {AxiosResponse} from "axios";
import {serverResponseNoData, serverResponseSuccessCode} from "../model/ServerResponse";

class AxiosHttpClient implements HttpClient {

    public async get<T>(url: string): Promise<T> {
        let response = await axios.get(url);
        this.checkResponse(response);
        if (!response.data.data) {
            throw new Error(serverResponseNoData);
        }

        return response.data.data;
    }

    public async post<T>(url: string, body: any): Promise<T> {
        let response = await axios.post(url, body);
        this.checkResponse(response);
        if (!response.data.data) {
            throw new Error(serverResponseNoData);
        }

        return response.data.data;
    }

    private checkResponse(response: AxiosResponse): void {
        if (!response) {
            throw new Error('null');
        } else if (response.status >= 300) {
            throw new Error(`${response.status}`);
        } else if (response.data.responseCode !== serverResponseSuccessCode) {
            throw new Error(`${response.data.responseCode}: ${response.data.responseMessage}`);
        }
    }
}

export default AxiosHttpClient;