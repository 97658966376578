import React from "react";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import ContentBox from "../../../../components/content-box/ContentBox";
import PageActions from "../../../base/PageActions";
import '../../../styles/Common.scss';
import './TelegramStickersPage.scss';

interface TelegramStickerPageProps {
    dependencies: Dependencies,
    env: Environment
}

const TelegramStickersPage = (props: TelegramStickerPageProps) => {

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Telegram Sticker Pack - Chase the Fox`,
        description: `Add Chase's official Telegram sticker pack to your collection. New stickers added periodically!`,
        keywords: ["telegram stickers", "sticker pack"],
        imgSrc: `chase/assets/chase_sticker.png`,
        url: `telegram-stickers`
    });

    return page.generateMetaContent(
        <>
            <ContentBox className="sticker-content" header="Chase the Fox Telegram Sticker Pack">
                <p>
                    Introducing Chase's official Telegram sticker pack! This pack will contains all legacy stickers
                    redrawn and updated with a new style! The pack will be updated every week with new stickers as
                    well. Once all 24 original stickers are included, brand new ones will be added after that! To
                    install the pack, click the image link below:
                </p>
                <a className="sticker-pack-link"
                   href="https://t.me/addstickers/chasethefox"
                   target="_blank" rel="noopener noreferrer">
                    <img src={page.image().getCdnImageSrc('chase/assets/chase_sticker_pack.png')}
                         alt="Click here to install!"
                         title="Chase the Fox Official Sticker Pack"/>
                </a>
                <p>
                    If you would like to install the original sticker packs or want all the stickers in their old
                    style prior to their redraw and release, you can add those to your collection as well. The links
                    for installing the legacy sticker packs can be found below!
                </p>
                <p className="sticker-packs-legacy">
                    <a href="https://t.me/addstickers/chasethefox1"
                       title="Install Sticker Pack 1"
                       target="_blank" rel="noopener noreferrer">
                        Sticker Pack 1
                    </a>
                    <a href="https://t.me/addstickers/chasethefox2"
                       title="Install Sticker Pack 1"
                       target="_blank" rel="noopener noreferrer">
                        Sticker Pack 2
                    </a>
                </p>
            </ContentBox>
        </>
    );
}

export default TelegramStickersPage;