import React from "react";
import {NavLink} from "react-router-dom";
import './BannerImage.scss';

interface BannerImageProps {
    link: string,
    src: string,
    alt: string,
    circular: boolean
}

const BannerImage = (props: BannerImageProps) => {

    return (
        <div id="header-img">
            <NavLink to={props.link}>
                <img className={(props.circular) ? "circular-image" : "hanging-image"}
                     src={props.src}
                     alt={props.alt}/>
            </NavLink>
        </div>
    );
}

export default BannerImage;