import React from "react";
import {NavLink} from "react-router-dom";
import SiteTheme from "../../model/SiteTheme";
import DeviceService from "../../services/DeviceService";
import './Footer.scss';

interface FooterProps {
    theme: SiteTheme,
    window: DeviceService,
    themeUpdater?: (path: string) => void
}

const Footer = (props: FooterProps) => {

    return (
        <>
            <div id="footer">
                <div id="footer-credits">
                    Character, concepts, art and site coding by <NavLink to="/contact" target="_blank" rel="noopener noreferrer">Chase the Fox</NavLink><br/>
                    &copy; 2015-2024 Chase the Fox
                    <button className="top-button" onClick={() => props.window.goToTop()}>Return to Top</button>
                </div>
            </div>
        </>
    );
}

export default Footer;