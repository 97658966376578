import React, {useMemo, useState} from "react";
import {NavLink} from "react-router-dom";
import Dependencies from "../../../../../../utilities/Dependencies";
import Environment from "../../../../../../services/interfaces/Environment";
import PageActions from "../../../../../base/PageActions";
import CasarokCommonWord from "../../../../../../components/common-word/CasarokCommonWord";
import '../../../../../styles/Common.scss';
import '../../WorldPages.scss';
import './WorldCommonLanguagePage.scss';

interface WorldCommonLanguagePageProps {
    dependencies: Dependencies,
    env: Environment
}

const WorldCommonLanguagePage = (props: WorldCommonLanguagePageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Casarok: Common (Languages) - Chase the Fox`,
        description: `Casarok Common is the most spoken language in the modern times of Chase's world.`,
        keywords: ["casarok", "chase's world", "fantasy language", "fictional language", "casarok common"],
        imgSrc: `world/languages/common/preview.png`,
        url: `chase/world/languages/common`
    }), [props]);

    const [state, setState] = useState<string>('introduction');

    return page.generateMetaContent(
        <>
            <div className="world-book" key={`world-languages`}>
                <div className="world-navigation">
                    <NavLink to={`/world`} className="world-link glowing block">World</NavLink><span>&gt;</span>
                    <NavLink to={`/world/languages`} className="world-link glowing block">Languages</NavLink>
                    <span>&gt;</span><span className="world-link block">Common</span>
                </div>
                <div id="page-left" className="world-book-page">
                    <h2 className="center">Casarok Common</h2>
                    <p>
                        The history, alphabet, and rules of the Common language can be found below. Different sections
                        can be revealed using this selector:
                    </p>
                    <div className="center">
                        <select defaultValue="introduction"
                                onChange={e => setState(e.target.value)}>
                            <option value="introduction">Introduction</option>
                            <option value="alphabet">Alphabet</option>
                            <option value="modifiers">Modifiers</option>
                            <option value="vowels">Vowels</option>
                            <option value="syntax-and-punctuation">Syntax and Punctuation</option>
                            <option value="borrowed-letters">Special Notes</option>
                            <option value="numerals">Numerals</option>
                            <option value="further-numbering">Further Numbering</option>
                        </select>
                    </div>
                </div>
                <div id="page-right" className="world-book-page">
                    <div hidden={state !== 'introduction'}>
                        <h3>Introduction</h3>
                        <p>
                            The Casarok Common language (most commonly referred to as only "Common") is the most spoken
                            language in the post-Cataclysmic world. It was created by a think tank composed of linguists
                            from all five regions of the world at the beginning of the Crusade Age. The think tank was
                            organized by the Central Kingdoms that has been built up from the remnants of the Populis
                            Empire with the intention of unifying all regions under one common language to help break
                            down barriers of diplomacy and trade to create a world more brought together when Casarok
                            was still a single continent. The paladins of the Central Kingdoms would then go on
                            "Crusades" to spread this language and their culture to the farthest ends of Casarok.
                            It quickly spread and became the primary language throughout the world by the oncoming of
                            the Feudal Age.
                        </p>
                        <p>
                            Part of the reason for Common's popularity and ease of adoption is its flexibility. The
                            language was designed to encompass all major languages used throughout the world, borrowing
                            parts from each one and leaving the door open for future expansion as new tongues and
                            words would be derived. It also relied heavily on the alphabet used in Lingua Dracammarum,
                            which was already so well-established that it was nearly universally recognizable to all
                            anthros in the world. However, due to blatant racism against the Dracammas, many alterations
                            were made to appease those who were affected by them. These reasons have been lost to time
                            in the Post-Cataslysmic Era of Casarok history.
                        </p>
                        <p>
                            Common is a phonetic language, meaning that each of its characters represents a specific
                            sound or set of sounds rather than the pronunciation dictating how the word is written.
                            The alphabet is comprised of 25 characters. Modifiers on those characters can alter the
                            way each specific letter is pronounced. The order of the alphabet was set in the
                            Pre-Cataclysmic Era, so present scholars are unsure of how sequence came to be. That
                            ordering of all letters can be seen below:
                        </p>
                        <div className="center"><CasarokCommonWord code={'ASLMNEDBFGIHRWYOPTXUCZVQJ'}/></div>
                    </div>
                    <div hidden={state !== 'alphabet'}>
                        <h3>Casarok Common Alphabet</h3>
                        <p>
                            The alphabet in Common is made up of 25 letters, 20 consonants and 5 vowels. Each one
                            represents a single pronunciation. The names for the characters as well as an example of
                            how they are pronounced can be found in the following chart:
                        </p>
                        <table className="casarok-common-table last-column-wide">
                            <thead>
                                <tr>
                                    <th>Letter</th>
                                    <th>Name</th>
                                    <th>Example</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><CasarokCommonWord code={'A'}/></td>
                                <td>aeg</td>
                                <td><CasarokCommonWord code={'ACT'}/>: act</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'S'}/></td>
                                <td>sen</td>
                                <td><CasarokCommonWord code={'SIT'}/>: sit</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'L'}/></td>
                                <td>lum</td>
                                <td><CasarokCommonWord code={'LET'}/>: let</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'M'}/></td>
                                <td>ma</td>
                                <td><CasarokCommonWord code={'MAP'}/>: map</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'N'}/></td>
                                <td>ni</td>
                                <td><CasarokCommonWord code={'NET'}/>: net</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'E'}/></td>
                                <td>ent</td>
                                <td><CasarokCommonWord code={'END'}/>: end</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'D'}/></td>
                                <td>dac</td>
                                <td><CasarokCommonWord code={'DIG'}/>: dig</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'B'}/></td>
                                <td>baz</td>
                                <td><CasarokCommonWord code={'BAT'}/>: bat</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'F'}/></td>
                                <td>fla</td>
                                <td><CasarokCommonWord code={'FOG'}/>: fog</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'G'}/></td>
                                <td>gla</td>
                                <td><CasarokCommonWord code={'GET'}/>: get</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'I'}/></td>
                                <td>ig</td>
                                <td><CasarokCommonWord code={'IMP'}/>: imp</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'H'}/></td>
                                <td>her</td>
                                <td><CasarokCommonWord code={'HAT'}/>: hat</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'R'}/></td>
                                <td>ru</td>
                                <td><CasarokCommonWord code={'RAN'}/>: ran</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'W'}/></td>
                                <td>wic</td>
                                <td><CasarokCommonWord code={'WET'}/>: wet</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'Y'}/></td>
                                <td>yu</td>
                                <td><CasarokCommonWord code={'YAM'}/>: yam</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'O'}/></td>
                                <td>oc</td>
                                <td><CasarokCommonWord code={'ON'}/>: on</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'P'}/></td>
                                <td>pen</td>
                                <td><CasarokCommonWord code={'PEN'}/>: pen</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'T'}/></td>
                                <td>ten</td>
                                <td><CasarokCommonWord code={'TEN'}/>: ten</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'X'}/></td>
                                <td>ex</td>
                                <td><CasarokCommonWord code={'MIX'}/>: mix</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'U'}/></td>
                                <td>ung</td>
                                <td><CasarokCommonWord code={'UP'}/>: up</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'C'}/></td>
                                <td>chu</td>
                                <td><CasarokCommonWord code={'CAT'}/>: cat</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'Z'}/></td>
                                <td>shu</td>
                                <td><CasarokCommonWord code={'ZIP'}/>: ship</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'V'}/></td>
                                <td>thu</td>
                                <td><CasarokCommonWord code={'VIN'}/>: thin</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'Q'}/></td>
                                <td>tsu</td>
                                <td><CasarokCommonWord code={'IQ'}/>: its</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'J'}/></td>
                                <td>ing</td>
                                <td><CasarokCommonWord code={'SOJ'}/>: song</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div hidden={state !== 'modifiers'}>
                        <h3>Pronunciation Modifiers</h3>
                        <p>
                            A series of marks are added to individual letters to change how they read. These modifiers
                            are generally added above the letter.
                        </p>
                        <p>
                            The most common of these marks is the caret modifier, which completely changes the letter's
                            pronunciation. Only a few consonants utilize this mark. The list of changes for these
                            consonants includes:
                        </p>
                        <table className="example-table casarok-common-table">
                            <tbody>
                            <tr>
                                <td><CasarokCommonWord code={'^GAR'}/>: jar</td>
                                <td><CasarokCommonWord code={'^FET'}/>: vet</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'^WIC'}/>: quick</td>
                                <td><CasarokCommonWord code={'^SAP'}/>: zap</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'^COMP'}/>: chomp</td>
                                <td><CasarokCommonWord code={'^FI^ZIN'}/>: vision</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'^VIS'}/>: this</td>
                                <td><CasarokCommonWord code={'HI^J'}/>: hinge</td>
                            </tr>
                            </tbody>
                        </table>
                        <p>
                            Accent marks change the emphasis or intonation on a word. An upward accent mark denotes a
                            rising inflection while a downward mark conveys falling inflection. The upward accent is
                            used more often.
                        </p>
                        <p className="center"><CasarokCommonWord code={'RuECERD'}/>: record</p>
                        <p>
                            A halo over a letter means that it is spoken with brevity or left completely silent,
                            depending on the context.
                        </p>
                        <p className="center"><CasarokCommonWord code={'SsICA'}/>: sika <em>(Japanese)</em></p>
                        <p>
                            A double halo represents that a consonant spoken with a trill. (See the Vowels section for
                            more info on the overline regarding the oc or 'O' sound.)
                        </p>
                        <p className="center"><CasarokCommonWord code={'BUrRlO'}/>: burro <em>(Spanish)</em></p>
                        <p>
                            An underline simply shows a capitalized letter.
                        </p>
                        <p className="center"><CasarokCommonWord code={'cSNlO'}/>: Snow</p>
                    </div>
                    <div hidden={state !== 'vowels'}>
                        <h3>Vowels</h3>
                        <p>
                            There are 5 base vowel sounds in Casarok Common, but they may be altered in various ways
                            to produce new vowel sounds. The base vowel sounds are shown below:
                        </p>
                        <table className="example-table casarok-common-table">
                            <tbody>
                            <tr>
                                <td colSpan={2}><CasarokCommonWord code={'ACT'}/>: act</td>
                                <td colSpan={2}><CasarokCommonWord code={'END'}/>: end</td>
                                <td colSpan={2}><CasarokCommonWord code={'IMP'}/>: imp</td>
                            </tr>
                            <tr>
                                <td colSpan={3}><CasarokCommonWord code={'ON'}/>: on</td>
                                <td colSpan={3}><CasarokCommonWord code={'UP'}/>: up</td>
                            </tr>
                            </tbody>
                        </table>
                        <p>
                            The caret mark works on vowels the same way it does on consonants.
                        </p>
                        <table className="example-table casarok-common-table">
                            <tbody>
                            <tr>
                                <td colSpan={2}><CasarokCommonWord code={'L^AD'}/>: loud</td>
                                <td colSpan={2}><CasarokCommonWord code={'^V^E'}/>: the</td>
                                <td colSpan={2}><CasarokCommonWord code={'R^I^S'}/>: rise</td>
                            </tr>
                            <tr>
                                <td colSpan={3}><CasarokCommonWord code={'N^O^S'}/>: noise</td>
                                <td colSpan={3}><CasarokCommonWord code={'P^UT'}/>: put</td>
                            </tr>
                            </tbody>
                        </table>
                        <p>
                            The overline, or long mark, changes a vowel into a long vowel instead.
                        </p>
                        <table className="example-table casarok-common-table">
                            <tbody>
                            <tr>
                                <td colSpan={2}><CasarokCommonWord code={'lAR'}/>: air</td>
                                <td colSpan={2}><CasarokCommonWord code={'RlES'}/>: race</td>
                                <td colSpan={2}><CasarokCommonWord code={'lIT'}/>: eat</td>
                            </tr>
                            <tr>
                                <td colSpan={3}><CasarokCommonWord code={'lON'}/>: own</td>
                                <td colSpan={3}><CasarokCommonWord code={'SlUN'}/>: soon</td>
                            </tr>
                            </tbody>
                        </table>
                        <p>
                            Vowel sounds can change slightly when combined with ru or the 'R' sound.
                        </p>
                        <table className="example-table casarok-common-table">
                            <tbody>
                            <tr>
                                <td colSpan={2}><CasarokCommonWord code={'C^AR'}/>: car</td>
                                <td colSpan={2}><CasarokCommonWord code={'ERV'}/>: earth</td>
                                <td colSpan={2}><CasarokCommonWord code={'DlIR'}/>: deer</td>
                            </tr>
                            <tr>
                                <td colSpan={3}><CasarokCommonWord code={'DlOR'}/>: door</td>
                                <td colSpan={3}><CasarokCommonWord code={'C^UR'}/>: cure</td>
                            </tr>
                            </tbody>
                        </table>
                        <p>When the double halo modifier is used on a vowel, that sound is made longer than normal.</p>
                        <p className="center"><CasarokCommonWord code={'HrOClU'}/>: haku <em>(Japanese for understand)</em></p>
                    </div>
                    <div hidden={state !== 'syntax-and-punctuation'}>
                        <h3>Syntax and Punctuation</h3>
                        <p>
                            Syntax in Casarok Common is usually in the form of subject -&gt; action -&gt; object.
                            Writing is mostly done left-to-right as well. These rules are not set in stone and can vary
                            based on which part of the world the writing comes from. For example, top-to-bottom is used
                            on occasion in eastern countries.
                        </p>
                        <p>
                            Punctuation markings for words and sentences have a one-to-one correlation with English.
                            The following sentences show some examples of these marks in practice.
                        </p>
                        <p className="center"><CasarokCommonWord code={'cM^I NlEM I^S c^ClES.'}/></p>
                        <p className="center"><em>My name is Chase.</em><br/></p>
                        <p className="center"><CasarokCommonWord code={'cWlET UP!'}/></p>
                        <p className="center"><em>Wait up!</em><br/></p>
                        <p className="center"><CasarokCommonWord code={'cFlOR H^A LONG?'}/></p>
                        <p className="center"><em>For how long?</em><br/></p>
                        <p className="center"><CasarokCommonWord code={'cIT SE^Z: "cWUN cMINIT".'}/></p>
                        <p className="center"><em>It says: "One minute".</em><br/></p>
                        <p className="center"><CasarokCommonWord code={'c^I\'M LlI^F^EJ, c^ClES.'}/></p>
                        <p className="center"><em>I'm leaving, Chase.</em><br/></p>
                    </div>
                    <div hidden={state !== 'borrowed-letters'}>
                        <h3>Special Notes</h3>
                        <p>
                            The final five letters of the Common alphabet (chu, shu, thu, tsu, and ing) are referred to
                            as "borrowed letters". These are characters that came from the languages of the minor
                            Draconic races. They all represent some form of complex sound within the language expressed
                            in a single letter.
                        </p>
                        <p>
                            If a letter is meant to be spoken twice, then the letter is written twice.
                        </p>
                        <p className="center"><CasarokCommonWord code={'COPPU'}/>: koppu <em>(Japanese)</em></p>
                        <p>
                            For words in which a consonant acts like its own syllable, a tick mark is used before the
                            final letter in that syllable.
                        </p>
                        <p className="center"><CasarokCommonWord code={'HAND`L'}/>: handle</p>
                        <p>
                            Words of Drekaldrish decent that end in ru or the 'R' sound are written differently in
                            Common than normal using this method. For pluralized words of this sort, the alternate
                            version of ru using a caret mark is used.
                        </p>
                        <p className="center"><CasarokCommonWord code={'DRlECOLD`R'}/>: drekaldr</p>
                        <p className="center"><CasarokCommonWord code={'DRlECOLD^R'}/>: drekaldar</p>
                        <p>
                            Languages with more than two levels of accent marks or intonation tend to use double
                            accent marks to represent such words. Words that come from central Casarok languages tend
                            to use the two accent marks instead to show primary and secondary accents when necessary.
                        </p>
                    </div>
                    <div hidden={state !== 'numerals'}>
                        <h3>Basic Numerals</h3>
                        <p>
                            Numbers in the majority of Casarok are shown in a base-8 system, and the numerals in
                            Common are no different. There are only 8 digits used to represent all values. The following
                            table shows how the basic numerals are written and said:
                        </p>
                        <table className="numeral-common-table">
                            <thead>
                            <tr>
                                <th>Numeral</th>
                                <th>Name</th>
                                <th>Value</th>
                                <th>Base 8</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><CasarokCommonWord code={'0'}/></td>
                                <td>con</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'1'}/></td>
                                <td>surs</td>
                                <td>1</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'2'}/></td>
                                <td>par</td>
                                <td>2</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'3'}/></td>
                                <td>un</td>
                                <td>3</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'4'}/></td>
                                <td>man</td>
                                <td>4</td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'5'}/></td>
                                <td>ad</td>
                                <td>5</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'6'}/></td>
                                <td>ter</td>
                                <td>6</td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'7'}/></td>
                                <td>max</td>
                                <td>7</td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'10'}/></td>
                                <td>uter</td>
                                <td>8</td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'11'}/></td>
                                <td>uter-surs</td>
                                <td>9</td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'20'}/></td>
                                <td>paruter</td>
                                <td>16</td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td><CasarokCommonWord code={'100'}/></td>
                                <td>uber</td>
                                <td>64</td>
                                <td>100</td>
                            </tr>
                            </tbody>
                        </table>
                        <p>
                            The orb mark (similar to the period as a punctuation mark) is an optional inclusion for the
                            sake of readability on numbers represented by more than three digits. For example, the
                            number <CasarokCommonWord code={'1.000'}/>, called <em>ominis</em>, can be written with
                            or without the orb mark.
                        </p>
                        <p>The seven digit value <CasarokCommonWord code={'1.000.000'}/> is called a <em>nibus</em>.</p>
                        <p>The ten digit number <CasarokCommonWord code={'1.000.000.000'}/> is an <em>enimis</em>.</p>
                    </div>
                    <div hidden={state !== 'further-numbering'}>
                        <h3>Other Numbering Rules</h3>
                        <p>
                            Negative numbers are written out similarly to the English numbering system. The prefix
                            "sub-" is added to it instead of saying "negative".
                        </p>
                        <p className="center"><CasarokCommonWord code={'-1'}/>: sub-surs or -1</p>
                        <p>
                            Fractions are written the same as well, but they come with the prefix "ci". Numbers that
                            start with a vowel sound use "cis" instead.
                        </p>
                        <p className="center">
                            <CasarokCommonWord code={'1'}/>/<CasarokCommonWord code={'2'}/>: cipar or one half
                        </p>
                        <p className="center">
                            <CasarokCommonWord code={'2'}/>/<CasarokCommonWord code={'10'}/>: par cisuter or two eighths
                        </p>
                        <p>Numbers with a floating point use the ground-caret mark.</p>
                        <p className="center"><CasarokCommonWord code={'1,1'}/>: surs and cisuter or one and one eighth
                        </p>
                        <p>Ordinal numbers are represented using overline and written out using the suffix "lin".</p>
                        <p className="center"><CasarokCommonWord code={'l1'}/>: surslin or first</p>
                        <p>
                            Exponents are superscripts just like in the Arabic numbering system. Raising a value to
                            another value is written out as the two numbers with "attol" between them.
                        </p>
                        <p className="center">
                            <CasarokCommonWord code={'2'}/> to the power of <CasarokCommonWord code={'2'}/> is
                            written <em>par attol par</em> which is equal
                            to <CasarokCommonWord code={'4'}/> or <em>man</em>
                        </p>
                        <p>
                            Roots are written the same but with "radic" instead of "attol". For a square root, the par
                            is meant to be understood and does not need to be written.
                        </p>
                        <p className="center">
                            The square root of <CasarokCommonWord code={'4'}/> is written <em>radic man</em> which is
                            equal to <CasarokCommonWord code={'2'}/> or <em>par</em>
                        </p>
                        <p className="center">
                            The cube root of <CasarokCommonWord code={'10'}/> is written <em>un radic uter</em> which
                            is equal to <CasarokCommonWord code={'2'}/> or <em>par</em>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WorldCommonLanguagePage;