import DeviceService from "./DeviceService";
import Environment from "./interfaces/Environment";
import ImageSize from "./interfaces/ImageSize";

const _file_size_mapping: {[name: string] : string} = {
    desktop: 'd/',
    mobile: 'm/',
    thumbnail: 'thumb/',
    full: 'base/'
};

class ImageService {

    constructor(private deviceService: DeviceService,
                private env: Environment) {}

    public getCdnImageSrc(imageSrc: string, fileSizeOverride?: string): string {
        let fileSize = fileSizeOverride;
        if (!fileSize) {
            fileSize = this.deviceService.isMobile() ? 'mobile' : 'desktop';
        }

        return this.env.targetCdn + _file_size_mapping[fileSize] + imageSrc;
    }

    public getRemoteImageSrc(imageSrc: string, imageSize: ImageSize): string {
        let url: string = this.normalizeUrl(imageSrc);
        let size: ImageSize = imageSize;

        if (this.deviceService.isMobile()) {
            if (size === ImageSize._medium) {
                size = ImageSize._small;
            } else if (size === ImageSize._large) {
                size = ImageSize._medium;
            }
        }

        return `${url}${(size !== ImageSize._full ? `?size=${size.asString()}` : '')}`;
    }

    public getLocalImageSrc(imageSrc: string): string {
        if (!imageSrc.startsWith(this.env.targetClient)) {
            return this.env.targetClient + ((imageSrc.startsWith('/')) ? imageSrc.substring(1) : imageSrc);
        }

        return imageSrc;
    }

    private normalizeUrl(url: string): string {
        if (!url.startsWith(this.env.targetServer)) {
            return this.env.targetServer + ((url.startsWith('/')) ? url.substring(1) : url);
        }

        return url;
    }
}

export const FileSizes = _file_size_mapping;
export default ImageService;