import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import ContentBox from "../../../../../components/content-box/ContentBox";
import DonationTracker from "../../../../../components/donation-tracker/DonationTracker";
import DonationInfo from "../../../../../model/DonationInfo";
import PageActions from "../../../../base/PageActions";
import "../../../../styles/Common.scss";
import "../CharityStreamPage.scss";

interface GoCharityStreamPageProps {
    dependencies: Dependencies,
    env: Environment
}

const GoCharityStreamPage = (props: GoCharityStreamPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Charities: Gamers Outreach - Chase the Fox`,
        description: `Learn more about Chase's efforts to help hospital-bound children!`,
        keywords: ["twitch", "stream info", "chasetheredfox", "charity", "gamers outreach"],
        imgSrc: `chase/assets/chase_twitch_icon.png`,
        url: `stream/charities/go`
    }), [props]);

    const years: number[] = Array(new Date().getFullYear() - 2022)
        .fill(0)
        .map((e, index) => index + 2023);

    const [year, setYear] = useState<number>(years[0]);

    const [hasFetchedResult, setFetchedResult] = useState<boolean>(false);

    const result = useRef<DonationInfo>();

    const navigateTo = useNavigate();

    useEffect(() => {
        page.http().getDonations("go", ""+year)
            .then(response => {
                if (!response) {
                    navigateTo("/error", {replace: false});
                } else {
                    result.current = response;
                }
            })
            .catch(() => navigateTo("/error", {replace: false}))
            .finally(() => setFetchedResult(true));
    }, [year]);

    const changeYear = (year: string): void => {
        setYear(+year);
        setFetchedResult(false);
    };

    return (
        <>
            <ContentBox className="donations-content-height"
                        header="Putting smiles on the faces of those who need them most!">
                <div>
                    <img src={page.image().getCdnImageSrc('chase/stream/charities/go_screen_cap.png')}
                         alt="Chase helping raise money for Gamers Outreach during one of his streams."
                         title="Let's GO, Fox Fam!"
                         className="width-50-percent block"/>
                    <p>
                        It's proven that ill children have a better quality of life and recover faster when they
                        are having fun. That is the mantra of Gamers Outreach. This great charity raises money to
                        provide children in hospitals with gaming equipment and experiences that aid their recovery.
                        They are a successful and well-organized charity, and I am happy to have partnered with
                        them!
                    </p>
                    <p>
                        I first participated in the <strong>Vtuber Summer Slam</strong> in 2023, and since then I've
                        worked with plenty of other gamers on various streaming platforms. Together, we have been able
                        to raise hundreds of thousands of dollars toward this effort!
                    </p>
                    <p>
                    Please consider <a href="https://gamersoutreach.org/donate/"
                                           target="_blank"
                                           rel="noopener noreferrer">
                            donating to Gamers Outreach
                        </a> to help us bring smiles to the faces of kids who need them.
                    </p>
                    <div style={{margin: "0 auto 16px", width: "fit-content"}}>
                        <label htmlFor="go-year-select">Select a year for the event:</label>
                        <select name="go-year-select"
                                onChange={event => changeYear(event.target.value)}>
                            {years.map(y => <option key={`donation-tracker-option-${y}`}
                                                            defaultValue={year}
                                                            value={y}>{''+y}</option>)}
                        </select>
                    </div>
                    {hasFetchedResult &&
                        <DonationTracker key={`donation-tracker-go-${Object.keys(result.current!).length}`}
                                         cause={`Vtuber Summer Slam ${year}`}
                                         donations={result.current!}/>
                    }
                </div>
            </ContentBox>
        </>
    );
}

export default GoCharityStreamPage;