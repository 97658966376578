import React, {ReactElement} from "react";
import {Navigate, Route} from "react-router-dom";
import Dependencies from "../../utilities/Dependencies";
import Environment from "../../services/interfaces/Environment";
import TaleChasersHomePage from "../../pages/chase/story/comic/talechasers/TaleChasersHomePage";
import ComicLatestRedirectPage from "../../pages/chase/story/comic/latest/ComicLatestRedirectPage";
import ComicDisplayPage from "../../pages/chase/story/comic/display/ComicDisplayPage";
import CharactersPage from "../../pages/chase/story/character/CharactersPage";
import CharacterDisplayPage from "../../pages/chase/story/character/display/CharacterDisplayPage";
import WorldPage from "../../pages/chase/story/world/main/WorldPage";
import WorldSectionPage from "../../pages/chase/story/world/section/WorldSectionPage";
import ChaseMagicPage from "../../pages/chase/story/chasemagic/ChaseMagicPage";
import WorldLanguagesPage from "../../pages/chase/story/world/languages/WorldLanguagesPage";
import WorldCommonLanguagePage from "../../pages/chase/story/world/languages/common/WorldCommonLanguagePage";

class StoryRouter {

    public static getRoutes(deps: Dependencies, env: Environment): ReactElement {
        return (
            <>
                <Route path="comics">
                    <Route index element={<Navigate replace to="talechasers"/>}/>
                    <Route path=":comic">
                        <Route index element={<TaleChasersHomePage dependencies={deps} env={env}/>}/>
                        <Route path="latest" element={<ComicLatestRedirectPage dependencies={deps} env={env}/>}/>
                        <Route path=":chapter">
                            <Route index element={<Navigate replace to="1"/>}/>
                            <Route path=":page" element={<ComicDisplayPage dependencies={deps} env={env}/>}/>
                        </Route>
                    </Route>
                </Route>
                <Route path="characters">
                    <Route index element={<CharactersPage dependencies={deps} env={env}/>}/>
                    <Route path=":name" element={<CharacterDisplayPage dependencies={deps} env={env}/>}/>
                </Route>
                <Route path="world">
                    <Route index element={<WorldPage dependencies={deps} env={env}/>}/>
                    <Route path="languages">
                        <Route index element={<WorldLanguagesPage dependencies={deps} env={env}/>}/>
                        <Route path="common">
                            <Route index element={<WorldCommonLanguagePage dependencies={deps} env={env}/>}/>
                        </Route>
                    </Route>
                    <Route path=":section">
                        <Route index element={<WorldSectionPage dependencies={deps} env={env}/>}/>
                        <Route path=":subsection">
                            <Route index element={<WorldSectionPage dependencies={deps} env={env}/>}/>
                            <Route path=":topic">
                                <Route index element={<WorldSectionPage dependencies={deps} env={env}/>}/>
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route path="chasemagic">
                    <Route index element={<ChaseMagicPage dependencies={deps} env={env}/>}/>
                </Route>
            </>
        );
    }
}

export default StoryRouter;