import React from 'react';
import {hydrate} from "react-dom";
import {createRoot} from "react-dom/client";
import reportWebVitals from './reportWebVitals';
import Site from "./Site";
import Dependencies from "./utilities/Dependencies";
import environment from "./environments/SiteEnvironment";
import HttpService from "./services/HttpService";
import AxiosHttpClient from "./clients/AxiosHttpClient";
import DeviceService from "./services/DeviceService";
import ImageService from "./services/ImageService";
import SeoService from "./services/SeoService";
import NamingService from "./services/NamingService";
import HtmlService from "./services/HtmlService";
import LoggingService from "./services/LoggingService";
import './index.scss';

const rootElement = document.getElementById('root') as HTMLElement;

const loggingService: LoggingService = new LoggingService();

const http: HttpService = new HttpService(new AxiosHttpClient(), loggingService, environment);
const device: DeviceService = new DeviceService(environment);
const image: ImageService = new ImageService(device, environment);
const seo: SeoService = new SeoService(image, environment);
const naming: NamingService = new NamingService();
const html: HtmlService = new HtmlService(image, loggingService);

const dependencies: Dependencies = {
    httpService: http,
    imageService: image,
    deviceService: device,
    seoService: seo,
    namingService: naming,
    htmlService: html
};

const ChaseTheFoxSite = () => {
    return <Site deps={dependencies} env={environment}/>;
}

createRoot(rootElement).render(
    <React.StrictMode>
        <ChaseTheFoxSite />
    </React.StrictMode>
);
if (device.isCrawler()) {
    hydrate(
        <React.StrictMode>
            <ChaseTheFoxSite />
        </React.StrictMode>,
    rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
