import React from "react";
import Dependencies from "../../../utilities/Dependencies";
import Environment from "../../../services/interfaces/Environment";
import ContentBox from "../../../components/content-box/ContentBox";
import PageActions from "../../base/PageActions";
import '../../styles/Common.scss';
import '../../styles/Adventurers.scss';
import './AdventurersHome.scss';

interface AdventurersHomeProps {
    dependencies: Dependencies,
    env: Environment
}

const AdventurersHome = (props: AdventurersHomeProps) => {

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Caudalglen Guild of Adventurers`,
        description: `Come hither and learn about the adventures for hire! Characters by Chase the Fox.`,
        keywords: ["caudalglen", "adventurers' guild", "fantasy world", "furry fantasy", "caudalglen guild of adventurers"],
        imgSrc: `cga/theme/logo.png`,
        url: `cga`
    });

    return page.generateMetaContent(
        <>
            <ContentBox design="scroll">
                <div>
                    <p id="guild-intro">
                        Greetings, traveler! Come hither and hear about the greatest adventurers for hire in the land!
                        We are the Caudalglen Guild of Adventurers led by the ranger, Aerilyn. We are a diverse group
                        of anthropomorphic animals from every stretch of this world seeking to do right amongst its
                        populace. Certainly there are trials and tribulations along the way, but we will face all
                        challenges head on. New members could be joining at any time, so keep a look out for updates
                        to our roster and our tales. Grab a chair, a pint, and join us as we celebrate our latest
                        victory!
                    </p>
                    <img className="guild-group-photo"
                         src={page.image().getCdnImageSrc(`cga/assets/group_photo.png`)}
                         alt="The main members of the CGA"
                         title="We are the Guild of Adventurers!"/>
                </div>
            </ContentBox>
        </>
    );
}

export default AdventurersHome;