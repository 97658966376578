import React, {ReactElement} from "react";
import {Navigate, Route} from "react-router-dom";
import Dependencies from "../../utilities/Dependencies";
import Environment from "../../services/interfaces/Environment";
import CommissionsPage from "../../pages/chase/art/commissions/CommissionsPage";
import CommissionTosPage from "../../pages/chase/art/commissions/tos/CommissionTosPage";
import FanArtSubmissionPage from "../../pages/chase/art/fan-art/submissions/FanArtSubmissionPage";
import TelegramStickersPage from "../../pages/chase/art/telegram/TelegramStickersPage";
import ShopPage from "../../pages/chase/art/shop/ShopPage";

class ArtRouter {

    public static getRoutes(deps: Dependencies, env: Environment): ReactElement {
        return (
            <>
                <Route path="gallery">
                    <Route index element={<Navigate replace to="fan-art/submit"/>}/>
                    <Route path="fan-art">
                        <Route index element={<Navigate replace to="submit"/>}/>
                        <Route path="submit" element={<FanArtSubmissionPage dependencies={deps} env={env}/>}/>
                    </Route>
                </Route>
                <Route path="commissions">
                    <Route index element={<CommissionsPage dependencies={deps} env={env}/>}/>
                    <Route path="tos" element={<CommissionTosPage dependencies={deps} env={env}/>}/>
                </Route>
                <Route path="shop">
                    <Route index element={<ShopPage dependencies={deps} env={env}/>}/>
                </Route>
                <Route path="telegram-stickers">
                    <Route index element={<TelegramStickersPage dependencies={deps} env={env}/>}/>
                    <Route path="install" element={<Navigate replace to="https://t.me/addstickers/chasethefox"/>}/>
                </Route>
            </>
        );
    }
}

export default ArtRouter;