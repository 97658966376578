import React, {ReactElement} from "react";
import {Route} from "react-router-dom";
import Dependencies from "../../utilities/Dependencies";
import Environment from "../../services/interfaces/Environment";
import PatchNotesPage from "../../pages/chase/site/patch-notes/PatchNotesPage";
import ContactPage from "../../pages/chase/site/contact/ContactPage";

class SiteInfoRouter {

    public static getRoutes(deps: Dependencies, env: Environment): ReactElement {
        return (
            <>
                <Route path="contact">
                    <Route index element={<ContactPage dependencies={deps} env={env}/>}/>
                </Route>
                <Route path="patch-notes" element={<PatchNotesPage dependencies={deps} env={env}/>}/>
            </>
        );
    }
}

export default SiteInfoRouter;