import React, {useEffect, useMemo, useRef, useState} from "react";
import {Params, useLocation, useNavigate, useParams} from "react-router-dom";
import SpeechBubble from "../../../../components/speech-bubble/SpeechBubble";
import GuildCharacterData from "../../../../model/GuildCharacterData";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import ContentBox from "../../../../components/content-box/ContentBox";
import PageActions from "../../../base/PageActions";
import '../../../styles/Common.scss';
import '../../../styles/Adventurers.scss';
import './AdventurersCharacterDisplayPage.scss';

interface AdventurersCharacterDisplayPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface AdventurersCharacterDisplayPageParams extends Params {
    name: string
}

const AdventurersCharacterDisplayPage = (props: AdventurersCharacterDisplayPageProps) => {

    const params = useParams<AdventurersCharacterDisplayPageParams>();
    const [name, setName] = useState<string>(params.name!);

    const character = useRef<GuildCharacterData>();

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Meet ${props.dependencies.namingService.capitalizeName(name)} - Caudalglen Guild of Adventurers`,
        description: `Meet ${props.dependencies.namingService.capitalizeName(name)}, from the world of the Adventurers' Guild.`,
        keywords: ["oc", "character", "fantasy character", "adventurers' guild", name],
        imgSrc: `cga/characters/${name}/1.png`,
        url: `cga/characters/${name}`
    }), [props, name]);

    const [hasFetchedResult, setFetchedResult] = useState<boolean>(false);

    const navigateTo = useNavigate();
    const location = useLocation();

    useEffect(() => {
        page.http().getGuildCharacterData(name)
            .then(response => {
                if (!response || Object.keys(response).length === 0 || !response.category.includes('GUILD')) {
                    navigateTo("/error", {replace: true});
                } else {
                    character.current = response;
                }
            })
            .catch(() => navigateTo("/error", {replace: true}))
            .finally(() => setFetchedResult(true));
    }, [name]);

    const navigateToLocation = () => {
        let path = location.pathname
        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }

        if (path.startsWith('cga/characters/') || path.startsWith('/cga/characters/')) {
            setName(path.substring(path.lastIndexOf('cga/characters/') + 'cga/characters/'.length));
            setFetchedResult(false);
        } else {
            navigateTo(path, {replace: false});
        }

        page.device().goToTop();
    };

    return page.generateMetaContent(
        <>
            <ContentBox design="scroll">
                {(!hasFetchedResult || !character.current) ? <></> : <>
                    <div>
                        <SpeechBubble text={character.current?.quote || ''} tail={page.device().isMobileWidth() ? 'middle' : 'right'}/>
                        <div className="character-info">
                            <div className="character-image character-image-right">
                                <img
                                    src={page.image().getCdnImageSrc(`cga/characters/${name}/1.png`)}
                                    alt={page.naming().capitalizeName(name)}
                                    className="character"/>
                            </div>
                            <p>
                                <strong>Species: </strong><span>{page.html().parse(character.current?.species || '', () => navigateToLocation())}</span> (<em>{page.html().parse(character.current?.speciesScientificName || '', () => navigateToLocation())}</em>)<br/>
                                <strong>Gender: </strong> <span>{character.current?.gender}</span><br/>
                                <br/>
                                <strong>Class: </strong><span>{character.current?.characterClass}</span><br/>
                                {(character.current?.role)
                                    ? <><strong>Guild Role: </strong>
                                        <span>{page.html().parse(character.current.role || '', () => navigateToLocation())}</span><br/></>
                                    : <></>}
                                {(character.current?.otherNames)
                                    ? <><strong>Other names: </strong>
                                        <span>{page.html().parse(character.current.otherNames || '', () => navigateToLocation())}</span><br/></>
                                    : <></>}
                                <strong>Height: </strong><span>{character.current?.heightUs}</span> (<span>{character.current?.heightMetric}</span>)<br/>
                                <strong>Primary Weapon: </strong> <span>{character.current?.primaryWeapon}</span><br/>
                                {(character.current?.secondaryWeapon)
                                    ? <><strong>Secondary Weapon: </strong>
                                        <span>{page.html().parse(character.current.secondaryWeapon || '', () => navigateToLocation())}</span><br/></>
                                    : <></>}
                                {(character.current?.magicElement)
                                    ? <><strong>Magical Expertise: </strong>
                                        <span>{page.html().parse(character.current.magicElement || '', () => navigateToLocation())}</span><br/></>
                                    : <></>}
                                <strong>Armor Type: </strong> <span>{character.current?.armor}</span><br/>
                                <br/>
                                <strong>Likes: </strong> <span>{page.html().parse(character.current?.likes || '', () => navigateToLocation())}</span><br/>
                                <strong>Dislikes: </strong> <span>{page.html().parse(character.current?.dislikes || '', () => navigateToLocation())}</span>
                            </p>
                            <p>
                                <strong>Character Traits: </strong>
                                <span>{page.html().parse(character.current?.traits || '', () => navigateToLocation())}</span>
                            </p>
                            <p>
                                <strong>Abilities: </strong>
                                <span>{page.html().parse(character.current?.abilities || '', () => navigateToLocation())}</span>
                            </p>
                            <p>
                                <strong>Background: </strong>
                                <span>{page.html().parse(character.current?.story1 || '', () => navigateToLocation())}</span>
                            </p>
                            <p>
                                <span>{page.html().parse(character.current?.story2 || '', () => navigateToLocation())}</span>
                            </p>
                        </div>
                    </div>
                </>}
            </ContentBox>
        </>
    );
}

export default AdventurersCharacterDisplayPage;