import React from "react";
import {NavLink} from "react-router-dom";
import './ImageLink.scss';

interface ImageLinkProps {
    imgSrc: string,
    linkUrl: string,
    linkName: string
}

const ImageLink = (props: ImageLinkProps) => {

    return (
        <div className="image-link">
            <NavLink to={props.linkUrl}>
                <img src={props.imgSrc}
                     alt={`Link to ${props.linkName}`}
                     className={!props.linkName ? 'bordered-image-link' : ''}
                />
                {props.linkName}
            </NavLink>
        </div>
    );
}

export default ImageLink;