export type GeneratorFlag = 'INCLUDE' | 'EXCLUDE' | 'ONLY';

export const GeneratorCategory: string[] =
    ['All', 'Mammals', 'Birds', 'Reptiles', 'Amphibians', 'Fish', 'Insects', 'Other'];

export const GeneratorColorSet: {[key: string]: string} = {
    ALL: "All colors",
    BASIC: "A set of 14 basic colors",
    NEUTRAL: "Whites, blacks, and greys",
    WARM: "Reds, oranges, and yellows",
    COOL: "Greens, blues, and purples"
};

export const TfGeneratorModifiers: {[key: string]: string} = {
    NONE: "No",
    INCLUDE: "Yes",
    SURPRISE_ME: "Surprise me!"
};

export const FursonaGeneratorModifiers: {[key: string]: string} = {
    NONE: "None",
    INCLUDE: "Any",
    TOY: "Toys",
    TECHNOLOGICAL: "Technological",
    MATERIAL: "Material",
    ELEMENTAL: "Elemental",
    OTHER: "Other"
};
