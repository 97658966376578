import React, {useMemo} from "react";
import Dependencies from "../../../utilities/Dependencies";
import Environment from "../../../services/interfaces/Environment";
import ContentBox from "../../../components/content-box/ContentBox";
import PageActions from "../../base/PageActions";
import '../../styles/Common.scss';
import '../../styles/Adventurers.scss';
import './AdventurersContactPage.scss';

interface ContactPageProps {
    dependencies: Dependencies,
    env: Environment
}

const AdventurersContactPage = (props: ContactPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Contact - Caudalglen Guild of Adventurers`,
        description: `Contact Chase over email or drop him a note on social media!`,
        keywords: ["contact", "contact info", "contact information", "email", "social links"],
        imgSrc: `cga/assets/contact_chase.png`,
        url: `cga/contact`
    }), [props]);

    return page.generateMetaContent(
        <>
            <ContentBox className="guild-contact" design="scroll">
                <div>
                    <h2>Chase the Fox</h2>
                    <h4>Website administrator, designer, coder, and artist</h4>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <img className="contact-image"
                                     src={page.image().getCdnImageSrc(`common/social/email_icon.png`)}
                                     alt="Email"/>
                            </td>
                            <td>chasereynard [[at]] gmail.com</td>
                        </tr>
                        <tr>
                            <td>
                                <a href="https://twitter.com/ChaseTheRedFox" target="_blank" rel="noopener noreferrer">
                                    <img
                                        className="contact-image"
                                        src={page.image().getCdnImageSrc(`common/social/twitter_logo.png`)}
                                        alt="Twitter"/>
                                </a>
                            </td>
                            <td><a href="https://twitter.com/ChaseTheRedFox" target="_blank" rel="noopener noreferrer">@ChaseTheRedFox</a></td>
                        </tr>
                        <tr>
                            <td>
                                <a href="https://www.furaffinity.net/user/chasereynard" target="_blank" rel="noopener noreferrer">
                                    <img
                                        className="contact-image"
                                        src={page.image().getCdnImageSrc(`common/social/fa_logo.png`)}
                                        alt="FurAffinity"/>
                                </a>
                            </td>
                            <td><a href="https://www.furaffinity.net/user/chasereynard" target="_blank" rel="noopener noreferrer">ChaseReynard</a></td>
                        </tr>
                        <tr>
                            <td>
                                <a href="src/pages/chase/site/contact/ContactPage" target="_blank" rel="noopener noreferrer">
                                    <img
                                        className="contact-image"
                                        src={page.image().getCdnImageSrc(`common/social/da_logo.png`)}
                                        alt="DeviantArt"/>
                                </a>
                            </td>
                            <td><a href="src/pages/chase/site/contact/ContactPage" target="_blank" rel="noopener noreferrer">ChaseReynard</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="character-image contact-right">
                    <img
                        className="character-pic"
                        src={page.image().getCdnImageSrc(`cga/assets/contact_chase.png`)}
                        alt="Contact Chase"/>
                </div>
            </ContentBox>
        </>
    );
}

export default AdventurersContactPage;