import React, {useEffect, useState} from "react";
import CommonProps from "../CommonProps";
import DeviceService from "../../services/DeviceService";
import './SchemeToggle.scss';

interface SchemeToggleProps extends CommonProps {
    window: DeviceService
}

const SchemeToggle = (props: SchemeToggleProps) => {

    const dataTheme: string = props.window.getFromLocalStorage('data-theme') 
        || (props.window.prefersDarkTheme() ? 'dark' : 'light');
    const [isDark, setDark] = useState<boolean>(dataTheme === 'dark');
    
    useEffect(() => props.window.setAttribute('data-theme', dataTheme), []);

    const switchScheme = (): void => {
        if (!isDark) {
            props.window.setAttribute('data-theme', 'dark');
            props.window.setInLocalStorage('data-theme', 'dark');
        }
        else {
            props.window.setAttribute('data-theme', 'light');
            props.window.setInLocalStorage('data-theme', 'light');
        }

        setDark(!isDark);
    }

    return (
        <>
            <div className="scheme-switch-wrapper">
                <label className="scheme-switch" htmlFor="checkbox">
                    <input type="checkbox"
                           id="checkbox"
                           checked={isDark}
                           onChange={() => switchScheme()}/>
                    <div className="scheme-slider round"></div>
                </label>
            </div>
        </>
    );
}

export default SchemeToggle;