import React, {FormEvent, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import TfGeneratorStore, {TfGenActions} from "../../../../store/TfGeneratorStore";
import TfGenQuery from "../../../../model/TfGenQuery";
import {GeneratorCategory, TfGeneratorModifiers} from "../../../../model/generator/GeneratorTypes";
import ContentBox from "../../../../components/content-box/ContentBox";
import PageActions from "../../../base/PageActions";
import '../../../styles/Common.scss';
import '../../../styles/Form.scss';
import './TfGeneratorPage.scss';

interface TfGeneratorPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface TfGeneratorPageState {
    query: TfGenQuery
}

const TfGeneratorPage = (props: TfGeneratorPageProps) => {

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Random Transformation Generator - Chase the Fox`,
        description: `Feel like changing for a change? What will Chase zap you into next?`,
        keywords: ["TF Generator", "transformation generator", "transformation randomizer", "TF randomizer"],
        imgSrc: `chase/assets/tf_victim.png`,
        url: `tf-generator`
    });

    const [state, setState] = useState<TfGeneratorPageState>({
        query: {...TfGeneratorStore.getState()}
    });

    const navigateTo = useNavigate();
    
    const isValidForm = (): boolean => {
        let count = 0;
        [TfGeneratorStore.getState().extinct,
            TfGeneratorStore.getState().mythical,
            TfGeneratorStore.getState().casarokanUnique]
        .forEach(value => {
            if (value === 'ONLY') {
                count++;
            }
        });

        return count < 2;
    }

    const submitForm = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        page.device().goToTop();
        navigateTo('result');
    }

    return page.generateMetaContent(
        <>
            <ContentBox header="Random Transformation Generator (Beta)">
                <div className="tf-gen-intro">
                    <img src={page.image().getCdnImageSrc('chase/assets/chase_thinking.png')}
                         alt="Hmmm..."
                         title="I'm thinking about what I should do..."/>
                    <p>
                        You want me to change you into something? Alright then. There are a lot of possibilities out
                        there. I can just pick anything at random if you want:
                    </p>
                    <NavLink className="quick-tf-button" to="result?quick=true">Give me a random transformation!</NavLink>
                    <p>
                        But if you have a specific request, you can tell me what you want below and I will do my
                        best to accommodate you!
                    </p>
                </div>
                <form className="form-body" onSubmit={(event) => submitForm(event)}>
                    <div>
                        <div className="tf-form-col-labels">
                            <label htmlFor="category">Category:</label>
                        </div>
                        <div className="tf-form-col-content">
                            <select id="category-select"
                                    name="category"
                                    value={state.query.category}
                                    onChange={(event) => {
                                        let category = event.target.value;
                                        setState({query: {...state.query, category}});
                                        TfGeneratorStore.dispatch(TfGenActions.updateCategory(category));
                                    }}
                            >
                                {GeneratorCategory.map(key =>
                                    <option key={`tf-gen-category-${key.toLowerCase()}`}
                                            value={key.substring(0, key.endsWith('s') ? key.length - 1 : key.length).toUpperCase()}>
                                        {key}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div>
                        <div className="tf-form-col-labels">
                            <label htmlFor="extinct">Extinct Creatures:</label>
                        </div>
                        <div className="tf-form-col-content">
                            <input id="extinct-include"
                                   name="extinct"
                                   type="radio"
                                   value="include"
                                   checked={state.query.extinct === 'INCLUDE'}
                                   disabled={state.query.mythical === 'ONLY'
                                       || state.query.casarokanUnique === 'ONLY'}
                                   onChange={() => {
                                       setState({query: {...state.query, extinct: 'INCLUDE'}});
                                       TfGeneratorStore.dispatch(TfGenActions.updateExtinct('INCLUDE'));
                                   }}
                            />
                            <label htmlFor="extinct-include">Include</label>
                            <input id="extinct-exclude"
                                   name="extinct"
                                   type="radio"
                                   value="exclude"
                                   checked={state.query.extinct === 'EXCLUDE'}
                                   disabled={state.query.mythical === 'ONLY'
                                       || state.query.casarokanUnique === 'ONLY'}
                                   onChange={() => {
                                       setState({query: {...state.query, extinct: 'EXCLUDE'}});
                                       TfGeneratorStore.dispatch(TfGenActions.updateExtinct('EXCLUDE'));
                                   }}
                            />
                            <label htmlFor="extinct-exclude">Exclude</label>
                            <input id="extinct-only"
                                   name="extinct"
                                   type="radio"
                                   value="only"
                                   checked={state.query.extinct === 'ONLY'}
                                   disabled={state.query.mythical === 'ONLY'
                                       || state.query.casarokanUnique === 'ONLY'}
                                   onChange={() => {
                                       setState({query: {...state.query, extinct: 'ONLY'}});
                                       TfGeneratorStore.dispatch(TfGenActions.updateExtinct('ONLY'));
                                   }}
                            />
                            <label htmlFor="extinct-only">Only</label>
                        </div>
                    </div>
                    <div>
                        <div className="tf-form-col-labels">
                            <label htmlFor="mythical">Mythical Creatures:</label>
                        </div>
                        <div className="tf-form-col-content">
                            <input id="mythical-include"
                                   name="mythical"
                                   type="radio"
                                   value="include"
                                   checked={state.query.mythical === 'INCLUDE'}
                                   disabled={state.query.extinct === 'ONLY'
                                       || state.query.casarokanUnique === 'ONLY'}
                                   onChange={() => {
                                       setState({query: {...state.query, mythical: 'INCLUDE'}});
                                       TfGeneratorStore.dispatch(TfGenActions.updateMythical('INCLUDE'));
                                   }}
                            />
                            <label htmlFor="mythical-include">Include</label>
                            <input id="mythical-exclude"
                                   name="mythical"
                                   type="radio"
                                   value="exclude"
                                   checked={state.query.mythical === 'EXCLUDE'}
                                   disabled={state.query.extinct === 'ONLY' || state.query.casarokanUnique === 'ONLY'}
                                   onChange={() => {
                                       setState({query: {...state.query, mythical: 'EXCLUDE'}});
                                       TfGeneratorStore.dispatch(TfGenActions.updateMythical('EXCLUDE'));
                                   }}
                            />
                            <label htmlFor="mythical-exclude">Exclude</label>
                            <input id="mythical-only"
                                   name="mythical"
                                   type="radio"
                                   value="only"
                                   checked={state.query.mythical === 'ONLY'}
                                   disabled={state.query.extinct === 'ONLY'
                                       || state.query.casarokanUnique === 'ONLY'}
                                   onChange={() => {
                                       setState({query: {...state.query, mythical: 'ONLY'}});
                                       TfGeneratorStore.dispatch(TfGenActions.updateMythical('ONLY'));
                                   }}
                            />
                            <label htmlFor="mythical-only">Only</label>
                        </div>
                    </div>
                    <div>
                        <div className="tf-form-col-labels">
                            <label htmlFor="casarokan">Casarokan Creatures:</label>
                        </div>
                        <div className="tf-form-col-content">
                            <input id="casarokan-include"
                                   name="casarokan"
                                   type="radio"
                                   value="include"
                                   checked={state.query.casarokanUnique === 'INCLUDE'}
                                   disabled={state.query.extinct === 'ONLY'
                                       || state.query.mythical === 'ONLY'}
                                   onChange={() => {
                                       setState({query: {...state.query, casarokanUnique: 'INCLUDE'}});
                                       TfGeneratorStore.dispatch(TfGenActions.updateCasarokan('INCLUDE'));
                                   }}
                            />
                            <label htmlFor="casarokan-include">Include</label>
                            <input id="casarokan-exclude"
                                   name="casarokan"
                                   type="radio"
                                   value="exclude"
                                   checked={state.query.casarokanUnique === 'EXCLUDE'}
                                   disabled={state.query.extinct === 'ONLY'
                                       || state.query.mythical === 'ONLY'}
                                   onChange={() => {
                                       setState({query: {...state.query, casarokanUnique: 'EXCLUDE'}});
                                       TfGeneratorStore.dispatch(TfGenActions.updateCasarokan('EXCLUDE'));
                                   }}
                            />
                            <label htmlFor="casarokan-exclude">Exclude</label>
                            <input id="casarokan-only"
                                   name="casarokan"
                                   type="radio"
                                   value="only"
                                   checked={state.query.casarokanUnique === 'ONLY'}
                                   disabled={state.query.extinct === 'ONLY'
                                       || state.query.mythical === 'ONLY'}
                                   onChange={() => {
                                       setState({query: {...state.query, casarokanUnique: 'ONLY'}});
                                       TfGeneratorStore.dispatch(TfGenActions.updateCasarokan('ONLY'));
                                   }}
                            />
                            <label htmlFor="casarokan-only">Only</label>
                        </div>
                        <div>
                            <div className="tf-form-col-labels">
                                <label htmlFor="category">Modifier:</label>
                            </div>
                            <div className="tf-form-col-content">
                                {Object.keys(TfGeneratorModifiers).map(key =>
                                    <span key={`modifier-radio-${key}`}>
                                        <input id={`modifier-${key}`}
                                               name={key}
                                               type="radio"
                                               value={key}
                                               checked={state.query.modifier === key}
                                               onChange={() => {
                                                   setState({query: {...state.query, modifier: key}});
                                                   TfGeneratorStore.dispatch(TfGenActions.updateModifier(key));
                                               }}
                                        />
                                        <label htmlFor={`modifier-${key}`}>{TfGeneratorModifiers[key]}</label>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="tf-form-buttons">
                            <input type="submit"
                                   value="I submit my request!"
                                   disabled={!isValidForm()}/>
                            <input type="button"
                                   value="Wait! Please reset!"
                                   onClick={() => {
                                       setState({query: {...TfGeneratorStore.getState()}});
                                       TfGeneratorStore.dispatch(TfGenActions.reset());
                                   }}
                            />
                        </div>
                        <div className="tf-form-col-content">
                            <span className="form-warning" hidden={isValidForm()}>
                                At most one field can be marked as only!
                            </span>
                        </div>
                    </div>
                </form>
                <div>
                    <p className="center">
                        You can find the full list of options by
                        clicking <NavLink to="list" target="_blank" rel="noreferrer noopener">here</NavLink>.
                    </p>
                </div>
            </ContentBox>
        </>
    );
}

export default TfGeneratorPage;