import React, {useMemo, useState} from "react";
import {NavLink} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import ContentBox from "../../../../../components/content-box/ContentBox";
import PageActions from "../../../../base/PageActions";
import '../../../../styles/Common.scss';
import '../ComicPage.scss';

interface TaleChasersHomePageProps {
    dependencies: Dependencies,
    env: Environment
}

interface TaleChasersHomePageState {
    selectedChapter: number
}

const TaleChasersHomePage = (props: TaleChasersHomePageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Tale Chasers - Chase the Fox`,
        description: `A webcomic, written and drawn by Chase the Fox, about Chase, his friends, and their fantastical adventures!`,
        keywords: ["comic", "webcomic", "tale chasers", "talechasers", "comic by chase"],
        imgSrc: `chase/comic/talechasers/tale_chasers_logo.png`,
        url: `comics/talechasers`
    }), [props]);

    const [state, setState] = useState<TaleChasersHomePageState>({
        selectedChapter: 3
    });

    return page.generateMetaContent(
        <ContentBox>
            <img className="comic-header"
                 src={page.image().getCdnImageSrc(`chase/comic/talechasers/tale_chasers_logo.png`)}
                 alt="Tale Chasers"/>
            <div className="world-content">
                <p className="center">
                    Art and story done by Chase the Fox
                </p>
                <p className="center">
                    Tale Chasers is an episodic comic that centers around Chase and his friends as they go
                    on various adventures and get into trouble along the way. There's always a new tale to
                    chase after in the world of Casarok!
                </p>
                <p className="center">
                    <NavLink to="latest">You can jump to the newest page by clicking here!</NavLink>
                </p>
                <p className="center">
                    <NavLink to="1/1">Do you need to start from the beginning instead?</NavLink>
                </p>
            </div>
            <div className="center">
                <label htmlFor="chapter-select">Select a chapter: </label>
                <select id="chapter-select"
                        value={state.selectedChapter}
                        onChange={(event) =>
                            setState({selectedChapter: +event.target.value})}
                >
                    <option value={1}>Chapter 1: Matters of Size</option>
                    <option value={2}>Chapter 2: In Your Paws</option>
                    <option value={3}>Chapter 3: Two of Clubs</option>
                </select>
            </div>
            <div className="talechasers-preview" hidden={state.selectedChapter !== 1}>
                <p className="center">
                    <strong>Chapter 1</strong>
                </p>
                <p>
                    <strong><NavLink to="1/1">Matters of Size</NavLink></strong>: Chase accompanies
                    Malichi at the annual robotic engineering convention. However, things get out of hand
                    when Malichi runs into an old friend.
                    <img className="character-pic"
                         src={page.image().getCdnImageSrc(`chase/characters/malichi/1.png`)}
                         alt="Malichi" />
                </p>
            </div>
            <div className="talechasers-preview" hidden={state.selectedChapter !== 2}>
                <p className="center">
                    <strong>Chapter 2</strong>
                </p>
                <p>
                    <strong><NavLink to="2/1">In Your Paws</NavLink></strong>: Chase, Ruby, and Snow go on an
                    archaeological expedition in the frozen north. Things take an interesting turn when they
                    uncover a mysterious artifact within the ruins.
                    <img className="character-pic"
                         src={page.image().getCdnImageSrc(`chase/characters/ruby/1.png`)}
                         alt="Ruby" />
                </p>
            </div>
            <div className="talechasers-preview" hidden={state.selectedChapter !== 3}>
                <p className="center">
                    <strong>Chapter 3</strong>
                </p>
                <p>
                    <strong><NavLink to="3/1">Two of Clubs</NavLink></strong>: In a game of hearts, the player with
                    the two of clubs starts the contest...
                    <img className="character-pic"
                         src={page.image().getCdnImageSrc(`chase/characters/two/silhouette.png`)}
                         alt="Who is this mysterious character?" />
                </p>
            </div>
        </ContentBox>
    );
}

export default TaleChasersHomePage;