import React, {useEffect, useRef, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import GeneratorItemList from "../../../../../model/generator/GeneratorItemList";
import ContentBox from "../../../../../components/content-box/ContentBox";
import PageActions from "../../../../base/PageActions";
import '../../../../styles/Common.scss';

interface FursonaGeneratorListPageProps {
    dependencies: Dependencies,
    env: Environment
}

const FursonaGeneratorListPage = (props: FursonaGeneratorListPageProps) => {

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Fursona Generator Entry List - Chase the Fox`,
        description: `Lists all possible results from the Random Fursona Generator`,
        keywords: ["fursona generator", "fursona randomizer", "furry generator", "furry randomizer"],
        imgSrc: `chase/assets/chase_artist.png`,
        url: `fursona-generator/list`
    });

    const [fetchComplete, setFetchComplete] = useState<boolean>(false);

    const navigateTo = useNavigate();

    let list = useRef<GeneratorItemList>();

    useEffect(() => {
        page.http().getFursonaGeneratorItems()
            .then(data => list.current = data)
            .catch(() => navigateTo("/error", {replace: false}))
            .finally(() => setFetchComplete(true));
    }, []);

    return page.generateMetaContent(
        <>
            <ContentBox header="All Possible Categories for the Fursona Generator">
                <p className="center">
                    A listing for all possible entries that can be given for a random fursona generation.
                    If you notice a problem or would like to suggest an addition or change, then
                    please <NavLink to="/contact">contact Chase</NavLink>!
                </p>
                <ul>
                    {fetchComplete &&
                        Object.keys(list.current!.result).map((header, index) => {
                        return <li key={`fur-gen-list-category-${index}`}>
                            {header.substring(0, 1).toUpperCase() + header.substring(1)}
                            <ul>
                                {list.current!.result[`${header}`].map((item, i2) => {
                                    return <li key={`fur-gen-list-${header}-${i2}`}>
                                        {item.name.substring(0, 1).toUpperCase() + item.name.substring(1).toLowerCase()}
                                        {(item.subNames) ? <ul>
                                                {item.subNames.map((subItem, i3) => {
                                                    return <li key={`fur-gen-list-${header}-${item}-${i3}`}>
                                                        {subItem.substring(0, 1).toUpperCase() + subItem.substring(1).toLowerCase()}
                                                    </li>
                                                })}
                                            </ul> : <></>}
                                    </li>
                                })}
                            </ul>
                        </li>
                    })}
                </ul>
            </ContentBox>
        </>
    );
}

export default FursonaGeneratorListPage;