import React from "react";
import GymBadgeData from "./GymBadgeData";
import './GymBadge.scss';

const GymBadge = (props: GymBadgeData) => {

    const getMessage = (): string => {
        if (props.badge === 'test') {
            return "Participated in the initial testing of the Casarokan Gym Challenge!";
        }

        let name = props.badge.charAt(0).toUpperCase()
            + props.badge.substring(1).toLowerCase()
            + " Gym Challenge";
        if (props.badge === 'megaman2023') {
            name = "Mega May 2023 Gym Challenge";
        }

        switch (props.level) {
            case 1:
                return `Completed the Bronze ` + name + " on " + props.awardDate;
            case 2:
                return `Completed the Silver ` + name + " on " + props.awardDate;
            case 3:
                return `Completed the Gold ` + name + " on " + props.awardDate;
            default:
                return "Participated in the " + name + " on " + props.awardDate;
        }
    }

    return (
        <span className={`badge star-level-${Math.max(0, Math.min(3, props.level))}`}>
            <img src={`https://cdn.chasethefox.com/m/chase/stream/pokemon/badges/${props.badge}.png`}
                 alt={`Gym Badge: ${props.badge}`}
                 title={getMessage()}/>
            <span title={getMessage()}>
                &#9733;
            </span>
        </span>
    );
}

export default GymBadge;