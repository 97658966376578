import React, {useMemo} from "react";
import {NavLink} from "react-router-dom";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import PageActions from "../../../base/PageActions";
import ContentBox from "../../../../components/content-box/ContentBox";
import "../../../styles/Common.scss";

interface SarStreamPageProps {
    dependencies: Dependencies,
    env: Environment
}

const SarStreamPage = (props: SarStreamPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Super Animal Royale - Chase the Fox`,
        description: "Learn about Chase's stream in terms of Super Animal Royale and related art!",
        keywords: ["twitch", "stream info", "chasetheredfox", "super animal royale", "gaming"],
        imgSrc: `chase/assets/chase_twitch_icon.png`,
        url: `stream/sar`
    }), [props]);

    return page.generateMetaContent(
        <>
            <ContentBox header="Hello, Super Animals!">
                <div>
                    <img src={page.image().getCdnImageSrc('chase/stream/sar/sar_logo.png')}
                         alt="The Super Animal Royale logo"
                         title="Fight for fur-vival!"
                         className="width-30-percent block"/>
                    <p>
                        I play Super Animal Royale, or SAR for short, fairly frequently on stream and am one of
                        their official content creators, regularly referred to as <strong>SAR CC</strong>. SAR is a
                        free-to-play top-down battle royale game that can be picked up on any launcher or platform
                        from Steam to Switch. You and 63 other combatants duke it out for supremacy; however, only
                        one animal can be at the top of the food chain!
                    </p>
                    <p>
                        If you happen to be in a SAR stream, chances are you can play with me. Most SAR streams are
                        reserved for team play with viewers, whether that be in the standard game mode or in other
                        fun ways. Even during art streams, I will draw some Super Animals or some cosmetic concept
                        art. So join me, and let's team up in this fight for fur-vival...and gratuitous animal puns!
                    </p>
                    <p className="center">
                        <NavLink to='characters'>
                            If you show up during a SAR stream, you will be able to redeem channel points for a
                            change to one of my cast of characters! Click here to view them!
                        </NavLink>
                    </p>
                </div>
            </ContentBox>
        </>
    );
}

export default SarStreamPage;