import HttpClient from "./interfaces/HttpClient";
import Environment from "./interfaces/Environment";
import CharacterData from "../model/CharacterData";
import CharacterOption from "../model/CharacterOption";
import CommissionType from "../model/CommissionType";
import WorldContent from "../model/WorldContent";
import LoggingService from "./LoggingService";
import TfGenResult from "../model/TfGenResult";
import TfGenQuery from "../model/TfGenQuery";
import GeneratorItemList from "../model/generator/GeneratorItemList";
import GuildCharacterData from "../model/GuildCharacterData";
import GymBadgeData from "../components/gym-badge/GymBadgeData";
import DonationInfo from "../model/DonationInfo";
import ComicMetadata from "../model/ComicMetadata";
import FursonaGenQuery from "../model/FursonaGenQuery";
import FursonaGenResult from "../model/FursonaGenResult";
import ShopEntry from "../model/ShopEntry";

class HttpService {

    constructor(private httpClient: HttpClient,
                private loggingService: LoggingService,
                private env: Environment) {}

    public async getComicChapters(comic: string): Promise<{[page: number] : ComicMetadata}> {
        try {
            return await this.httpClient.get<{[page: number] : ComicMetadata}>(
                `${this.env.targetServer}v1/comics/${comic}${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getComicChapterPages(comic: string, chapter: number): Promise<{[page: number] : ComicMetadata}> {
        try {
            return await this.httpClient.get<{[page: number] : ComicMetadata}>(
                `${this.env.targetServer}v1/comics/${comic}/chapters/${chapter}${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getLatestComicPage(comic: string): Promise<number[]> {
        try {
            return await this.httpClient.get<number[]>(
                `${this.env.targetServer}v1/comics/${comic}/latest${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getCharacterOptions(): Promise<CharacterOption[]> {
        try {
            return await this.httpClient.get<CharacterOption[]>(
                `${this.env.targetServer}v1/characters${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getCharacterData(shortName: string): Promise<CharacterData> {
        try {
            return await this.httpClient.get<CharacterData>(
                `${this.env.targetServer}v1/characters/${shortName}${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getCommissionInfo(): Promise<{[p: string]: string}> {
        try {
            return await this.httpClient.get<{[name: string] : string}>(
                `${this.env.targetServer}v1/commissions/data${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getGuildCharacterOptions() {
        try {
            return await this.httpClient.get<CharacterOption[]>(
                `${this.env.targetServer}v1/guildcharacters${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getGuildCharacterData(shortName: string) {
        try {
            return await this.httpClient.get<GuildCharacterData>(
                `${this.env.targetServer}v1/guildcharacters/${shortName}${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getCommissionTypes(): Promise<CommissionType[]> {
        try {
            return await this.httpClient.get<CommissionType[]>(
                `${this.env.targetServer}v1/commissions/types${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getWorldContentByPath(path: string): Promise<WorldContent> {
        try {
            return await this.httpClient.get<WorldContent>(
                `${this.env.targetServer}v1/world/content/world/${path.replace(/^world\//g, '')}${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getDonations(key: string, year: string): Promise<DonationInfo> {
        try {
            return await this.httpClient.get<DonationInfo>(
                `${this.env.targetServer}v1/donations/${key}-${year}/twitch${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getTransformationGeneratorItems(): Promise<GeneratorItemList> {
        try {
            let url = `${this.env.targetServer}v1/generators/transformation/lists/species${this.env.fileExtension || ''}`;
            return await this.httpClient.get<GeneratorItemList>(url);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getFursonaGeneratorItems(): Promise<GeneratorItemList> {
        try {
            let url = `${this.env.targetServer}v1/generators/fursona/lists/species${this.env.fileExtension || ''}`;
            return await this.httpClient.get<GeneratorItemList>(url);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    // TODO: Rework flags
    public async getTransformationGeneratorResult(query: TfGenQuery): Promise<TfGenResult> {
        try {
            let postQuery = {
                category: (query.category && query.category.toLowerCase() !== 'all') ? query.category.toUpperCase() : undefined,
                extinct: (query.extinct) ? query.extinct.toUpperCase() : 'EXCLUDE',
                mythical: (query.mythical) ? query.mythical.toUpperCase() : 'EXCLUDE',
                casarokanUnique: (query.casarokanUnique) ? query.casarokanUnique.toUpperCase() : 'EXCLUDE',
                modifierOption: query.modifier || 'NONE'
            };
            let url = `${this.env.targetServer}v1/generators/transformation${this.env.fileExtension || ''}`;
            return await this.httpClient.post<TfGenResult>(url, postQuery);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getTransformationQuickResult(): Promise<TfGenResult> {
        try {
            let url = `${this.env.targetServer}v1/generators/transformation/quick${this.env.fileExtension || ''}`;
            return await this.httpClient.get<TfGenResult>(url);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getFursonaGeneratorResult(query: FursonaGenQuery): Promise<FursonaGenResult> {
        try {
            let postQuery = {
                category: (query.category && query.category.toLowerCase() !== 'all') ? query.category.toUpperCase() : undefined,
                colors: query.colors || 0,
                colorSet: query.colorSet || 'ALL',
                modifierOption: query.modifier || 'NONE'
            };
            let url = `${this.env.targetServer}v1/generators/fursona${this.env.fileExtension || ''}`;
            return await this.httpClient.post<FursonaGenResult>(url, postQuery);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getPokemonPlayerNames(): Promise<string[]> {
        try {
            return await this.httpClient.get<string[]>(
                `${this.env.targetServer}v1/stream/games/pokemon/players${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getPokemonPlayerBadges(username: string): Promise<GymBadgeData[]> {
        try {
            return await this.httpClient.get<GymBadgeData[]>(
                `${this.env.targetServer}v1/stream/games/pokemon/players/${username}${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }

    public async getShopEntries(): Promise<ShopEntry[]> {
        try {
            return await this.httpClient.get<ShopEntry[]>(
                `${this.env.targetServer}v1/shop/entries${this.env.fileExtension || ''}`);
        } catch (e) {
            this.loggingService.log(e);
            throw e;
        }
    }
}

export default HttpService;