import SiteTheme from "../../model/SiteTheme";
import NavigationSet from "../../model/NavigationSet";

const _links: NavigationSet = {
    Home: '/cga',
    Characters: '/cga/characters',
    Contact: '/cga/contact',
    "To Chase's Home": '/'
};

const AdventurersTheme: SiteTheme = {
    bannerImage: `https://cdn.chasethefox.com/m/cga/theme/logo.png`,
    bannerImageCircular: false,
    bannerTitle: `https://cdn.chasethefox.com/m/cga/theme/title.png`,
    bannerTitleAlt: "Caudalglen Guild of Adventurers",
    navigationLinks: _links,
    fullReloadLinks: ['/']
};

export default AdventurersTheme;