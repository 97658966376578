import React, {useEffect, useRef, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import Dependencies from "../../../../utilities/Dependencies";
import CommissionType from "../../../../model/CommissionType";
import Environment from "../../../../services/interfaces/Environment";
import ContentBox from "../../../../components/content-box/ContentBox";
import PageActions from "../../../base/PageActions";
import '../../../styles/Common.scss';
import './CommissionsPage.scss';

interface CommissionsPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface CommissionPageResultInfo {
    status: string,
    formLink: string,
    queueLink: string,
    offerHtml: string
}

const CommissionsPage = (props: CommissionsPageProps) => {

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Commissions - Chase the Fox`,
        description: `Check out commission options and status for Chase's art!`,
        keywords: ["commission", "art commission", "cartoon commission", "cute art", "commission status"],
        imgSrc: `chase/commissions/shaded/image.png`,
        url: `commissions`
    });
    
    const [hasFetchedInfo, setFetchInfo] = useState<boolean>(false);
    const [hasFetchedTypes, setFetchTypes] = useState<boolean>(false);

    const navigateTo = useNavigate();

    const resultInfo = useRef<CommissionPageResultInfo>();
    const resultTypes = useRef<CommissionType[]>();

    useEffect(() => {
        page.http().getCommissionInfo()
            .then(response => {
                if (!response || Object.keys(response).length === 0) {
                    resultInfo.current = undefined;
                } else {
                    resultInfo.current = {
                        status:  response['status'],
                        formLink: response['form'],
                        queueLink: response['queue'],
                        offerHtml: response['offer']
                    };
                }
            })
            .catch(() => navigateTo("/error", {replace: false}))
            .finally(() => setFetchInfo(true));

        page.http().getCommissionTypes()
            .then(response => {
                if (!response || response.length === 0) {
                    resultTypes.current = undefined;
                } else {
                    resultTypes.current = response;
                }
            })
            .catch(() => navigateTo("/error", {replace: false}))
            .finally(() => setFetchTypes(true));
    }, []);

    const getStatus = (): string => {
        return (hasFetchedInfo && resultInfo.current && resultInfo.current.status) ? resultInfo.current?.status : '';
    }

    const getFormLink = (): string => {
        return (hasFetchedInfo && resultInfo.current && resultInfo.current.formLink)
            ? resultInfo.current?.formLink : '';
    }

    const getQueueLink = (): string => {
        return (hasFetchedInfo && resultInfo.current && resultInfo.current.queueLink)
            ? resultInfo.current?.queueLink : '';
    }

    const getOfferHtml = (): string => {
        return (hasFetchedInfo && resultInfo.current && resultInfo.current.offerHtml)
            ? resultInfo.current?.offerHtml : '';
    }

    return page.generateMetaContent(
        <>
            <ContentBox className="content world-content"
                        header="Commission Info">
                <h3 className="headline">
                    Status: {getStatus()}
                </h3>
                {(getStatus() && getStatus().trim().toLowerCase() !== 'closed')
                    ? <h3 className="headline">
                        <a href={getFormLink()} target="_blank" rel="noopener noreferrer">
                            If you would like a commission, please click here and fill out the form!
                        </a>
                    </h3>
                    : <></>
                }
                <p className="center">
                    <a href={getQueueLink()} target="_blank" rel="noopener noreferrer">Commission Queue</a>
                </p>
                <p className="center">
                    <NavLink to="tos" target="_blank" rel="noopener noreferrer">Terms of Service</NavLink>
                </p>
                <p className="center">
                    <strong>Current offer:</strong> <span>{getOfferHtml()}</span>
                </p>
                <p className="center">
                    For any questions regarding commissions, please see my <NavLink to="/contact">Contact</NavLink> page.
                </p>
                <hr/>
                <ul className="commission-type-list">
                    {hasFetchedTypes && resultTypes.current?.map(type => (
                        <li key={`commission-${type.exampleImage}`}>
                            <div className="commission-example-image">
                                <a href={page.image().getCdnImageSrc(`chase/commissions/${type.exampleImage}/image.png`, 'full')}
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <img
                                        className="commission-image"
                                        src={page.image().getCdnImageSrc(`chase/commissions/${type.exampleImage}/image.png`)}
                                        alt={`${page.naming().capitalizeName(type.name)} Example`}
                                    />
                                </a>
                            </div>
                            <p className="commissions-text">
                                <strong style={{fontSize: '24pt'}}>{type.name}: {type.price}</strong><br/>
                                <span>{page.html().parse(type.description)}</span>
                            </p>
                            <hr/>
                        </li>
                    ))}
                </ul>
            </ContentBox>
        </>
    );
};

export default CommissionsPage;